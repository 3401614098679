import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  TextField,
  IconButton,
  CircularProgress,
  Avatar,
  Dialog,
  DialogContent,
  Tooltip,
  useTheme
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { addMessage, setCurrentStep, updateFormData, resetChatbot } from '../../Redux/slices/chatBotSlice';
import { setProductId } from '../../Redux/slices/authSlice';
import { useNavigate, useLocation } from 'react-router-dom';
import ProductForm from '../Settings/ProductForm';
import makeRequest from '../../makeRequest';
import { FB_APP } from '../../constant';
import MUIAutocomplete from '../../components/MUIAutoComplete';
import EditIcon from '@mui/icons-material/Edit';
import ProductDetailsForm from './ProductDetailsForm';
import CloseIcon from '@mui/icons-material/Close';
import Zoom from 'react-medium-image-zoom';
import PrevArrow from '../../components/PrevArrow';
import NextArrow from '../../components/NextArrow';
import html2canvas from 'html2canvas';
import { loadImageFromUrl } from '../../helpers';


const steps = [
  {
    id: 'name',
    message: 'Hello there! My name is Frappe, and I\'m ready to assist you with your product onboarding process. Let\'s begin by creating a new product together. What name would you like to give it?',
    type: 'text',
    validate: (value) => !!value.trim(),
    error: 'Product name is required'
  },
  {
    id: 'productDetails',
    message: 'Great name! Please fill in the product details below:',
    type: 'productDetails',
    noBotMessage: true
  },
  {
    id: 'reviewProductDetails',
    message: '🔍 Review your product details:',
    type: 'reviewProductDetails',
    noBotMessage: true
  },
  {
    id: 'fetchMetadata',
    message: 'Would you like to fetch metadata from the website URL?',
    type: 'fetchMetadata',
    noBotMessage: true
  },
  {
    id: 'reviewMetadata',
    message: '📦 Metadata Fetched: Review and edit the fetched metadata below.',
    type: 'reviewMetadata',
    noBotMessage: true
  },
  {
    id: 'generateFrames',
    message: 'We have generated some preset frames for your social media posts. These frames can be selected and applied when scheduling your posts.',
    type: 'generateFrames',
  },
  {
    id: 'connectFacebook',
    message: '✅ Metadata fetched successfully! Connect to Facebook?',
    type: 'connectFacebook',
    noBotMessage: true
  },
  {
    id: 'selectChannels',
    message: '🔗 Select channels to link with your product:',
    type: 'selectChannels',
    noBotMessage: true
  },
  {
    id: 'editProduct',
    message: '✏️ Edit your product details:',
    type: 'editProduct',
    noBotMessage: true
  },
  {
    id: 'success',
    message: '🚀 Great All set! Click below to go to your dashboard.',
    type: 'success',
    // noBotMessage: true
  },

];


const Chatbot = ({ onSubmit }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { messages, currentStep, formData } = useSelector((state) => state.chatbot);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [processedSteps, setProcessedSteps] = useState(new Set());
  const [localSteps, setLocalSteps] = useState([]);
  const messagesEndRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isEditingProduct, setIsEditingProduct] = useState(false);
  const canvasRefs = useRef([...Array(4)].map(() => React.createRef()));



  useEffect(() => {
    // dispatch(resetChatbot());
    const currentStepObj = steps.find(step => step.id === currentStep);
    if (!currentStepObj || localSteps.includes(currentStep)) return;

    setLocalSteps(prev => [...prev, currentStep]);
    if (currentStep === 'name') {
      setProcessedSteps(prev => new Set([...prev, currentStep]));
      return;
    }

    if (!currentStepObj.noBotMessage) {
      dispatch(addMessage({
        type: 'bot',
        content: currentStepObj.message
      }));
    }
  }, [currentStep, localSteps]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages, currentStep]);

  useEffect(() => {
    if (currentStep === 'generateFrames' && formData.logo?.length > 0) {
      const drawPreviews = async () => {
        const logoUrl = formData.logo[0]?.url || formData.logo[0]?.attributes?.url;
        const img = await loadImageFromUrl(logoUrl);

        canvasRefs.current.forEach((canvasRef, index) => {
          const canvas = canvasRef.current;
          if (!canvas) return;

          const container = canvas.parentElement;
          const size = Math.min(container.clientWidth, container.clientHeight);

          canvas.width = size;
          canvas.height = size;

          const ctx = canvas.getContext('2d');
          ctx.clearRect(0, 0, canvas.width, canvas.height);

          const logoSize = size * 0.1;
          const padding = size * 0.05;

          let x, y;
          // eslint-disable-next-line default-case
          switch (index) {
            case 0: // top-left
              x = padding;
              y = padding;
              break;
            case 1: // bottom-left
              x = padding;
              y = canvas.height - padding - logoSize;
              break;
            case 2: // top-right
              x = canvas.width - padding - logoSize;
              y = padding;
              break;
            case 3: // bottom-right
              x = canvas.width - padding - logoSize;
              y = canvas.height - padding - logoSize;
              break;
          }

          ctx.drawImage(img, x, y, logoSize, logoSize);
          if (formData.url) {
            ctx.font = '500 8px Arial';
            ctx.fillStyle = '#FFFFFF';
            
            let textX, textY;
            // eslint-disable-next-line default-case
            switch (index) {
              case 0: // Top-left → Bottom-right
                ctx.textAlign = 'right';
                ctx.textBaseline = 'bottom';
                textX = canvas.width - padding;
                textY = canvas.height - padding;
                break;
              case 1: // Bottom-left → Top-right
                ctx.textAlign = 'right';
                ctx.textBaseline = 'top';
                textX = canvas.width - padding;
                textY = padding;
                break;
              case 2: // Top-right → Bottom-left
                ctx.textAlign = 'left';
                ctx.textBaseline = 'bottom';
                textX = padding;
                textY = canvas.height - padding;
                break;
              case 3: // Bottom-right → Top-left
                ctx.textAlign = 'left';
                ctx.textBaseline = 'top';
                textX = padding;
                textY = padding;
                break;
            }
            ctx.fillText(formData.url, textX, textY);
          }
        });
      };

      drawPreviews();

      const handleResize = () => drawPreviews();
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [currentStep, formData.logo, formData.url]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get('code');

    if (!code) return;

    const handleFacebookAuth = async () => {
      setLoading(true);
      try {
        const response = await makeRequest({
          url: `/api/facebook?code=${code}&redirect_uri=onboarding`,
          method: 'GET',
        });

        if (response?.data) {
          dispatch(addMessage({
            type: 'bot',
            content: '✅ Successfully connected to Facebook!'
          }));

          const productResponse = await makeRequest({
            url: `/api/products/${formData.id}?populate=*&publicationState=preview`,
            method: 'GET',
          });

          const modifiedResponse = {
            id: productResponse.data.id,
            ...productResponse.data.attributes,
            channels: productResponse.data.attributes.channels.data || [],
            media: productResponse.data.attributes.media.data?.map(item => ({
              id: item.id,
              ...item.attributes
            })) || [],
            productScreens: productResponse.data.attributes.productScreens.data?.map(item => ({
              id: item.id,
              ...item.attributes
            })) || [],
            logo: productResponse.data.attributes.logo.data
              ? { id: productResponse.data.attributes.logo.data.id, ...productResponse.data.attributes.logo.data.attributes }
              : null,
            frames: productResponse.data.attributes.frames || []
          };

          dispatch(updateFormData(modifiedResponse));
          dispatch(setCurrentStep('selectChannels'));

          dispatch(addMessage({
            type: 'bot',
            content: '✅ Facebook channels fetched! Select channels to link with your product.'
          }));

        }
      } catch (error) {
        dispatch(addMessage({
          type: 'bot',
          content: '⚠️ Facebook connection failed. Please try again.'
        }));
      } finally {
        setLoading(false);
        navigate(window.location.pathname, { replace: true });
      }
    };

    handleFacebookAuth();
  }, [location.search, navigate, dispatch]);


  const handleTextSubmit = async (e) => {
    e.preventDefault();
    const step = steps.find(s => s.id === currentStep);

    if (step.validate && !step.validate(inputValue)) {
      setError(step.error);
      return;
    }

    dispatch(addMessage({ type: 'user', content: inputValue }));
    dispatch(updateFormData({ [currentStep]: inputValue }));
    // proceedToNextStep();

    dispatch(setCurrentStep('productDetails'));
    dispatch(addMessage({
      type: 'bot',
      content: steps.find(s => s.id === 'productDetails').message
    }));
    setInputValue('');
    setError('');
  };

  const proceedToNextStep = () => {
    const currentIndex = steps.findIndex(s => s.id === currentStep);
    if (currentIndex < steps.length - 1) {
      dispatch(setCurrentStep(steps[currentIndex + 1].id));
    } else {
      onSubmit(formData);
    }
  };

  const handleProductSubmit = async (productData) => {
    try {
      setLoading(true);
      dispatch(addMessage({
        type: 'user',
        content: 'Product details submitted'
      }));

      const creationResponse = await onSubmit({
        ...productData,
        channels: productData.channels || [],
        files: productData.files || []
      });
      
      if (!creationResponse?.id) {
        throw new Error('Failed to get product ID from creation response');
      }

      const fullProductResponse = await makeRequest({
        url: `/api/products/${creationResponse.id}?populate=logo,channels,productScreens`,
        method: 'GET'
      });

      if (fullProductResponse?.data) {
        const { id, attributes } = fullProductResponse.data;

        dispatch(updateFormData({
          id,
          name: attributes.name,
          details: attributes.details,
          url: attributes.url,
          description: attributes.description || '',
          productScreens: attributes.productScreens?.data?.map(item => ({
            id: item.id,
            ...item.attributes
          })) || [],
          channels: attributes.channels?.data?.map(item => ({
            id: item.id,
            ...item.attributes
          })) || [],
          logo: attributes.logo?.data
            ? [{
              id: attributes.logo.data.id,
              url: attributes.logo.data.attributes.url,
              attributes: attributes.logo.data.attributes
            }]
            : []
        }));
      }


      dispatch(addMessage({
        type: 'bot',
        content: `✅ Product Created Successfully! 
          Name: ${productData.name}
          URL: ${productData.url}
          Details: ${productData.details.substring(0, 50)}...`
      }));

      dispatch(setCurrentStep('reviewProductDetails'));
      dispatch(addMessage({
        type: 'bot',
        content: '🔍 Review your product details below:'
      }));

    } catch (error) {
      dispatch(addMessage({
        type: 'bot',
        content: '⚠️ Failed to create product. Please try again.'
      }));
    } finally {
      setLoading(false);
    }
  };


  const handleFetchMetadata = async () => {
    try {
      setLoading(true);
      const productId = formData.id;

      const response = await makeRequest({
        url: `/api/products/${productId}/get-product-meta-data`,
        method: 'POST',
        data: { url: formData.url },
      });

      dispatch(updateFormData({
        ...formData,
        logo: formData.logo,
        description: response.description,
        productScreens: response.productScreens
      }));

      dispatch(addMessage({
        type: 'bot',
        content: `📦 Metadata Fetched:
          Description: ${response.description.substring(0, 100)}...
          Screens Found: ${response.productScreens.length}`
      }));

      dispatch(setCurrentStep('reviewMetadata'));

    } catch (error) {
      dispatch(addMessage({
        type: 'bot',
        content: '⚠️ Failed to fetch metadata. Please try again.'
      }));
    } finally {
      setLoading(false);
    }
  };

  const handleConnectFacebook = () => {
    const scopes = [
      'pages_show_list',
      'public_profile',
      'email',
      'business_management',
      'pages_read_engagement',
      'instagram_basic',
      'instagram_manage_insights',
      'ads_management',
      'catalog_management',
      'instagram_shopping_tag_products',
      'instagram_content_publish',
      'pages_manage_metadata',
      'instagram_manage_comments',
      'pages_manage_posts',
      'pages_manage_engagement',
      'pages_manage_instant_articles',
      'publish_video',
      'read_insights',
    ];

    const redirectUri = encodeURIComponent(`${window.location.href}`);
    const scopeStr = encodeURIComponent(scopes.join(','));
    const fbOAuthUrl = `https://www.facebook.com/v18.0/dialog/oauth?response_type=code&state=${Date.now()}&scope=${scopeStr}&display=popup&client_id=${FB_APP}&redirect_uri=${redirectUri}`;

    window.location.href = fbOAuthUrl;
  };

  const handleChannelLink = async (selectedChannels) => {
    try {
      setLoading(true);
      const productId = formData?.id;
      const currentProduct = await makeRequest({
        url: `/api/products/${productId}?populate=channels`,
        method: 'GET'
      });

      const existingChannelIds = currentProduct.data.attributes.channels.data.map(c => c.id);
      const selectedChannelIds = selectedChannels.map(c => c.id);

      const connectChannels = selectedChannelIds
        .filter(id => !existingChannelIds.includes(id))
        .map(id => ({ id, position: { end: true } }));

      const disconnectChannels = existingChannelIds
        .filter(id => !selectedChannelIds.includes(id))
        .map(id => ({ id }));

      const postData = {
        channels: {
          connect: connectChannels,
          disconnect: disconnectChannels
        }
      };

      const updateData = new FormData();
      updateData.append('data', JSON.stringify(postData));

      await makeRequest({
        method: 'PUT',
        url: `/api/products/${productId}`,
        data: updateData,
        headers: { "Content-Type": "multipart/form-data" }
      });

      const updatedProduct = await makeRequest({
        url: `/api/products/${productId}?populate=*`,
        method: 'GET'
      });

      const normalizedChannels = updatedProduct.data.attributes.channels.data
        .map(ch => ({
          id: ch.id,
          attributes: ch.attributes
        }));

      dispatch(updateFormData({
        channels: normalizedChannels,
        selectedChannels: normalizedChannels.map(ch => ({
          id: ch.id,
          label: `${ch.attributes.display_name} - ${ch.attributes.channel_type}`,
          data: ch
        }))
      }));

      dispatch(addMessage({
        type: 'bot',
        content: `✅ Successfully linked ${connectChannels.length} channels!`
      }));

      dispatch(setCurrentStep('success'));

    } catch (error) {
      dispatch(addMessage({
        type: 'bot',
        content: '⚠️ Failed to link channels. Please try again.'
      }));
    } finally {
      setLoading(false);
    }
  };

  const handleProductUpdate = async (productData, submitType = '') => {
    try {
      setLoading(true);
      const currentProduct = await makeRequest({
        url: `/api/products/${formData.id}?populate=channels,logo,productScreens`,
        method: 'GET'
      });

      const existingChannelIds = currentProduct.data.attributes.channels.data.map(c => c.id);
      const selectedChannelIds = productData.channels.map(c => c.id);

      const connectChannels = selectedChannelIds
        .filter(id => !existingChannelIds.includes(id))
        .map(id => ({ id, position: { end: true } }));

      const disconnectChannels = existingChannelIds
        .filter(id => !selectedChannelIds.includes(id))
        .map(id => ({ id }));

      const existingLogoId = formData.logo?.[0]?.id;
      const newLogo = productData.logo || [];
      const shouldDisconnectLogo = existingLogoId && newLogo.length === 0;

      const logoUpdate = shouldDisconnectLogo
        ? { disconnect: [existingLogoId] }
        : newLogo.map(item => ({ id: item.id }));


      const updatePayload = {
        ...formData,
        ...productData,
        channels: {
          connect: connectChannels,
          disconnect: disconnectChannels
        },

      };

      if (!updatePayload?.logoFile) {
        updatePayload.logo = logoUpdate;
      }

      const updateData = new FormData();
      if (updatePayload?.logoFile) {
        delete updatePayload.logo;
        delete updatePayload.logoFile
      }
      updateData.append('data', JSON.stringify({
        ...updatePayload,
        // logo: formattedLogo,
      }));

      if (productData?.logoFile) {
        updateData.append('logo', productData.logoFile);
      }

      if (productData.files?.length) {
        productData.files.forEach(file => {
          updateData.append('files', file);
        });
      }

      if (productData.removedFiles?.length) {
        updateData.append('removedFiles', JSON.stringify(productData.removedFiles));
      }

      const response = await makeRequest({
        method: 'PUT',
        url: `/api/products/${formData.id}`,
        data: updateData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.data) {
        const updatedProduct = await makeRequest({
          url: `/api/products/${formData.id}?populate=logo,channels,productScreens`,
          method: 'GET'
        });


        if (updatedProduct?.data) {
          const { id, attributes } = updatedProduct.data;

          const normalizedData = {
            id,
            name: attributes.name,
            details: attributes.details,
            url: attributes.url,
            description: attributes.description || '',
            productScreens: attributes.productScreens?.data?.map(item => ({
              id: item.id,
              ...item.attributes
            })) || [],
            channels: attributes.channels?.data?.map(item => ({
              id: item.id,
              ...item.attributes
            })) || [],
            logo: attributes.logo?.data
              ? [{
                id: attributes.logo.data.id,
                url: attributes.logo.data.attributes.url,
                attributes: attributes.logo.data.attributes
              }]
              : [],
          }

          dispatch(updateFormData(normalizedData));
          dispatch(setCurrentStep(submitType === 'normal' ? 'reviewProductDetails' : 'success'));
          dispatch(addMessage({
            type: 'bot',
            content: '✅ Product updated successfully!'
          }));
        }
      }
    } catch (error) {
      dispatch(addMessage({
        type: 'bot',
        content: '⚠️ Failed to update product. Please try again.'
      }))
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateFrames = async () => {
    try {
      setLoading(true);
      const tempCanvases = await Promise.all(
        canvasRefs.current.map(async (canvasRef, index) => {
          // const previewCanvas = canvasRef.current;
          const tempCanvas = document.createElement('canvas');
          tempCanvas.width = 1024;
          tempCanvas.height = 1024;
          const ctx = tempCanvas.getContext('2d');

          const logoUrl = formData.logo[0]?.url || formData.logo[0]?.attributes?.url;
          const img = await loadImageFromUrl(logoUrl);

          let x, y;
          // eslint-disable-next-line default-case
          switch (index) {
            case 0: x = 100; y = 100; break;
            case 1: x = 100; y = 1024 - 175; break;
            case 2: x = 1024 - 175; y = 100; break;
            case 3: x = 1024 - 175; y = 1024 - 175; break;
          }

          ctx.drawImage(img, x, y, 75, 75);

          if (formData.url) {
            ctx.font = '500 24px Arial';
            ctx.fillStyle = '#FFFFFF'; 

            let textX, textY;
            // eslint-disable-next-line default-case
            switch (index) {
              case 0: // Logo top-left → URL bottom-right
                ctx.textAlign = 'right';
                ctx.textBaseline = 'bottom';
                textX = 1024 - 20;
                textY = 1024 - 20;
                break;
              case 1: // Logo bottom-left → URL top-right
                ctx.textAlign = 'right';
                ctx.textBaseline = 'top';
                textX = 1024 - 20;
                textY = 20;
                break;
              case 2: // Logo top-right → URL bottom-left
                ctx.textAlign = 'left';
                ctx.textBaseline = 'bottom';
                textX = 20;
                textY = 1024 - 20;
                break;
              case 3: // Logo bottom-right → URL top-left
                ctx.textAlign = 'left';
                ctx.textBaseline = 'top';
                textX = 20;
                textY = 20;
                break;
            }
            
            ctx.fillText(formData.url, textX, textY);
          }
          return tempCanvas;
        })
      );

      const files = await Promise.all(
        tempCanvases.map(async (canvas, index) => {
          const idx = index + 1;
          const blob = await new Promise(resolve =>
            canvas.toBlob(resolve, 'image/png')
          );
          return {
            template: `default-frame-${idx}`,
            file: new File([blob], `default-frame-${idx}.png`, { type: 'image/png' })
          };
        })
      );

      const postData = new FormData();
      files.forEach(({ template, file }) => {
        postData.append('frames', file);
        postData.append('templates', template);
      });

      const framesResponse = await makeRequest({
        url: `/api/products/${formData.id}/frames`,
        method: 'POST',
        data: postData,
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if(framesResponse?.data){
        const uploadedFrames = framesResponse?.data || [];
        dispatch(updateFormData({ frames: uploadedFrames.map(fItem => 
          ({ 
            id: fItem.id, 
            template: fItem.template, 
            mediaId: fItem?.media?.id, 
            name: fItem?.media?.name,
            url: fItem?.media?.url 
          })) 
        }));
      }
      
      dispatch(addMessage({
        type: 'bot',
        content: '✅ Generated frames saved successfully!'
      }));
      dispatch(setCurrentStep('connectFacebook'));
    } catch (error) {
      dispatch(addMessage({
        type: 'bot',
        content: '⚠️ Failed to generate frames. Please try again.'
      }));
    } finally {
      setLoading(false);
    }
  }

  const renderStepComponent = () => {
    const step = steps.find(s => s.id === currentStep);
    if (!step) return null;

    switch (step.type) {
      case 'productDetails':
        return (
          <ListItem sx={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}>
            <Paper sx={{ p: 2, bgcolor: 'background.paper', borderRadius: '20px 20px 20px 5px', width: '100%' }}>
              <ProductDetailsForm
                initialValues={formData}
                onSubmit={handleProductSubmit}
              />
            </Paper>
          </ListItem>
        );
      case 'reviewProductDetails':
        return (
          <ListItem sx={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}>
            <Paper sx={{ p: 2, width: '100%' }}>
              <ProductDetailsForm
                initialValues={{
                  ...formData,
                  logo: Array.isArray(formData.logo) ? formData.logo : [formData.logo]
                }}
                onSubmit={(updatedData) => {
                  handleProductUpdate({
                    ...formData,
                    ...updatedData,
                  }, 'normal');
                  setIsEditingProduct(false);
                  dispatch(addMessage({
                    type: 'bot',
                    content: '✅ Product details updated!'
                  }));
                }}
                onEdit={() => setIsEditingProduct(true)}
                onProceed={() => {
                  dispatch(setCurrentStep('fetchMetadata'));
                  dispatch(addMessage({
                    type: 'bot',
                    content: steps.find(s => s.id === 'fetchMetadata').message
                  }));
                }}
                isReviewMode={!isEditingProduct}
              />
            </Paper>
          </ListItem>
        );
      case 'fetchMetadata':
        return (
          <ListItem sx={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}>
            <Paper sx={{ p: 2, bgcolor: 'background.paper', borderRadius: '20px 20px 20px 5px', width: '100%' }}>
              <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                <Button variant="contained" onClick={handleFetchMetadata} disabled={loading}>
                  Fetch Metadata
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    dispatch(addMessage({
                      type: 'bot',
                      content: 'You have skipped fetching metadata from the URL. In case you want to fetch it later, you can do so from the Settings page.'
                    }));
                    dispatch(setCurrentStep('generateFrames'));
                  }}
                >
                  Skip
                </Button>
              </Box>
            </Paper>
          </ListItem>
        );
      case 'reviewMetadata':
        return (
          <ListItem sx={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}>
            <Paper sx={{ p: 2, bgcolor: 'background.paper', borderRadius: '20px 20px 20px 5px', width: '100%' }}>
              {formData.description && (
                <TextField
                  label="Description"
                  value={formData.description || ''}
                  onChange={(e) => dispatch(updateFormData({ description: e.target.value }))}
                  multiline
                  rows={4}
                  fullWidth
                />
              )}

              {formData.productScreens?.length > 0 && (
                <Box sx={{ mt: 2 }}>
                  <Box
                    sx={{
                      border: `1px solid ${theme.palette.divider}`,
                      borderRadius: "8px",
                      p: 1,
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{ position: "relative" }}
                    >
                      Product Screens
                    </Typography>
                    <Box
                      sx={{
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "250px",
                        flexDirection: "column",
                        p: 1,
                      }}
                    >
                      <Box alignItems="center">
                        <Box
                          sx={{
                            position: "absolute",
                            left: 12,
                            top: "50%",
                            transform: "translateY(-50%)",
                          }}
                        >
                          <PrevArrow
                            onClick={() => {
                              setCurrentIndex((prevIndex) =>
                                (prevIndex - 1 + formData.productScreens.length) % formData.productScreens.length
                              );
                            }}
                            disabled={formData.productScreens.length <= 1}
                          />
                        </Box>
                        <Tooltip title="Click to view" arrow>
                          <Box
                            component="img"
                            src={
                              formData.productScreens[currentIndex]?.attributes
                                ? formData.productScreens[currentIndex]?.attributes?.url
                                : formData.productScreens[currentIndex].url
                            }
                            alt="Product Screens"
                            sx={{
                              width: 250,
                              height: 200,
                              objectFit: "cover",
                              borderRadius: "4px",
                              cursor: "pointer",
                            }}
                            onClick={() => setOpen(true)}
                          />
                        </Tooltip>
                        <Box
                          sx={{
                            position: "absolute",
                            right: 12,
                            top: "50%",
                            transform: "translateY(-50%)",
                          }}
                        >
                          <NextArrow
                            onClick={() => {
                              setCurrentIndex((prevIndex) =>
                                (prevIndex + 1) % formData.productScreens.length
                              );
                            }}
                            disabled={formData.productScreens.length <= 1}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}

              <Dialog
                open={open}
                onClose={() => setOpen(false)}
                maxWidth={false}
                sx={{
                  "& .MuiDialog-paper": {
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100vw",
                    height: "100vh",
                  },
                }}
              >
                <DialogContent
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "transparent",
                  }}
                >
                  <IconButton
                    onClick={() => setOpen(false)}
                    color="primary"
                    sx={{
                      color: "white",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.7)" },
                      position: "absolute",
                      right: 30,
                      top: 0,
                    }}
                  >
                    <CloseIcon />
                  </IconButton>

                  <Box
                    sx={{
                      position: "absolute",
                      left: 30,
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  >
                    <PrevArrow
                      onClick={() => {
                        setCurrentIndex((prevIndex) =>
                          (prevIndex - 1 + formData.productScreens.length) % formData.productScreens.length
                        );
                      }}
                      disabled={formData.productScreens.length <= 1}
                    />
                  </Box>

                  <Zoom>
                    <Box
                      component="img"
                      src={
                        formData.productScreens[currentIndex]?.attributes
                          ? formData.productScreens[currentIndex]?.attributes?.url
                          : formData.productScreens[currentIndex]?.url
                      }
                      alt={`Product Screen ${currentIndex + 1}`}
                      sx={{
                        maxWidth: "90%",
                        maxHeight: "90vh",
                        objectFit: "contain",
                        borderRadius: "8px",
                        cursor: "zoom-in",
                      }}
                    />
                  </Zoom>
                  <Box
                    sx={{
                      position: "absolute",
                      right: 35,
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  >
                    <NextArrow
                      onClick={() => {
                        setCurrentIndex((prevIndex) =>
                          (prevIndex + 1) % formData.productScreens.length
                        );
                      }}
                      disabled={formData.productScreens.length <= 1}
                    />
                  </Box>
                </DialogContent>
              </Dialog>

              <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                <Button
                  variant="contained"
                  onClick={async () => {
                    try {
                      setLoading(true);
                      await handleProductUpdate({
                        ...formData,
                        description: formData.description,
                      }, 'normal');
                      dispatch(setCurrentStep('generateFrames')); 
                    } catch (error) {
                      dispatch(addMessage({
                        type: 'bot',
                        content: '⚠️ Failed to update product description. Please try again.'
                      }));
                    } finally {
                      setLoading(false);
                    }
                  }}
                  disabled={loading}
                >
                  Proceed
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => dispatch(setCurrentStep('fetchMetadata'))}
                >
                  Re-Fetch Metadata
                </Button>
              </Box>
            </Paper>
          </ListItem>
        );
      
      case 'generateFrames':
        return (
          <ListItem sx={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}>
            <Paper sx={{
              p: 2,
              borderRadius: '20px 20px 20px 5px',
              width: '100%',
              mx: 'auto'
            }}>
              <Typography variant="h6" gutterBottom>
                Generated Frames
              </Typography>
              <Box sx={{
                display: 'grid',
                gridTemplateColumns: {
                  xs: 'repeat(2, 1fr)',
                  sm: 'repeat(2, 1fr)',
                  md: 'repeat(4, 1fr)'
                },
                gap: 2,
                width: '100%'
              }}>
                {[...Array(4)].map((_, index) => (
                  <Box
                    key={index}
                    sx={{
                      position: 'relative',
                      border: '1px solid',
                      borderColor: 'divider',
                      borderRadius: 1,
                      p: 1,
                    }}
                  >
                    <canvas
                      ref={canvasRefs.current[index]}
                      style={{
                        width: '100%',
                        height: '100%',
                        backgroundColor: '#eee',
                        borderRadius: '4px',
                      }}
                    />
                  </Box>
                ))}
              </Box>
              <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
                <Button
                  variant="contained"
                  onClick={handleGenerateFrames}
                  disabled={loading}
                >
                  Proceed
                </Button>
              </Box>
            </Paper>
          </ListItem>
        );
      case 'connectFacebook':
        return (
          <ListItem sx={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}>
            <Box sx={{ display: 'flex', gap: 2, mt: 2, width: '100%' }}>
              <Button variant="contained" onClick={handleConnectFacebook} disabled={loading}>Connect to Facebook</Button>
              <Button variant="outlined" onClick={() => {
                dispatch(addMessage({
                  type: 'bot',
                  content: 'You have skipped fetching channels from Facebook. In case you want to fetch them later, you can do so from the Settings page.'
                }));
                dispatch(setCurrentStep('success'))
              }}>Skip</Button>
            </Box>
          </ListItem>
        );
      case 'editProduct':
        return (
          <ListItem sx={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}>
            <Paper sx={{ p: 2, width: '100%' }}>
              <ProductForm
                formType="chatbot"
                initialValues={{
                  ...formData,
                  name: formData.name,
                  details: formData.details,
                  url: formData.url,
                  description: formData.description,
                  logo: formData.logo?.length > 0
                    ? [{
                      id: formData.logo[0].id,
                      url: formData.logo[0].url,
                      attributes: formData.logo[0].attributes
                    }]
                    : [],
                  productScreens: formData.productScreens?.map(ps => ({
                    id: ps.id,
                    url: ps.attributes.url,
                    attributes: ps.attributes
                  })) || []

                }}
                onSubmit={handleProductUpdate}
                onMetadataFetched={(success) => {
                  if (success) {
                    dispatch(addMessage({
                      type: 'bot',
                      content: '✅ Successfully fetched product metadata!'
                    }));
                    dispatch(updateFormData({
                      ...formData,
                      logo: formData.logo?.length > 0
                    ? [{
                      id: formData.logo[0].id,
                      url: formData.logo[0].url,
                      attributes: formData.logo[0].attributes
                    }]
                    : [],
                      description: formData.description,
                      productScreens: formData.productScreens
                    }));
                  }
                }}
                onCancel={() => dispatch(setCurrentStep('success'))}
                submitText="Update Product"
                hideFields={['media']}
              />

            </Paper>
          </ListItem>
        );
      case 'selectChannels':
        return (
          <ListItem sx={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}>
            <Paper sx={{ p: 2, width: '100%' }}>
              <MUIAutocomplete
                multiple
                label="Channels"
                selectedValue={formData.selectedChannels || []}
                apiType="REST"
                apiRequest={{
                  url: `/api/channels?populate=*&publicationState=preview`,
                  method: 'GET',
                }}
                fieldKey="display_name"
                onSelect={(selected) => {
                  const formattedSelected = selected.map(ch => ({
                    id: ch.data.id,
                    label: `${ch.data.attributes.display_name} - ${ch.data.attributes.channel_type}`,
                    data: ch.data
                  }));

                  dispatch(updateFormData({
                    selectedChannels: formattedSelected,
                    channels: formattedSelected.map(ch => ({
                      id: ch.id,
                      attributes: ch.data.attributes
                    }))
                  }));
                }}
                autoHighlight
              />
              <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
                <Button
                  variant="contained"
                  onClick={() => handleChannelLink(formData.selectedChannels)}
                  disabled={formData?.selectedChannels.length === 0 || loading}
                >
                  Link Selected Channels
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => dispatch(setCurrentStep('success'))}
                >
                  Skip
                </Button>
              </Box>
            </Paper>
          </ListItem>
        );
      case 'success':
        return (
          <ListItem sx={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: 2, width: '100%' }}>
              <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
                <Button
                  variant="contained"
                  onClick={() => {
                    dispatch(resetChatbot());
                    if (formData?.id) dispatch(setProductId(formData.id));
                    navigate('/dashboard', { replace: true });
                  }}
                >
                  Go to Dashboard
                </Button>

                <Button
                  variant="outlined"
                  onClick={() => dispatch(setCurrentStep('editProduct'))}
                  startIcon={<EditIcon />}
                >
                  Edit Product
                </Button>
              </Box>
            </Box>
          </ListItem>
        );
      default:
        return null;
    }
  };

  return (
    <Paper elevation={3} sx={{ height: '85vh', display: 'flex', flexDirection: 'column', borderRadius: 0, p: 3 }}>
      <Box sx={{ flex: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
        <List sx={{ flex: 1, overflow: 'auto', mb: 2, '& ul': { padding: 0 } }}>
          {messages.map((msg, index) => (
            <ListItem key={index} sx={{ justifyContent: msg.type === 'bot' ? 'flex-start' : 'flex-end', alignItems: 'flex-start' }}>
              {msg.type === 'bot' && <Avatar sx={{ bgcolor: 'primary.main', mr: 2 }}>F</Avatar>}
              <Paper sx={{
                p: 2,
                bgcolor: msg.type === 'bot' ? 'background.paper' : 'primary.main',
                color: msg.type === 'bot' ? 'text.primary' : 'primary.contrastText',
                borderRadius: msg.type === 'bot' ? '20px 20px 20px 5px' : '20px 20px 5px 20px',
                maxWidth: '75%'
              }}>
                <ListItemText primary={msg.content} primaryTypographyProps={{ sx: { wordBreak: 'break-word' } }} />
              </Paper>
            </ListItem>
          ))}
          {renderStepComponent()}
          <ListItem sx={{ p: 0, height: 0 }}>
            <div ref={messagesEndRef} />
          </ListItem>
        </List>

        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <CircularProgress />
          </Box>
        )}

        {currentStep === 'name' && (
          <Box component="form" onSubmit={handleTextSubmit} sx={{ position: 'sticky', bottom: 0, pt: 2 }}>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <TextField
                label="Type your answer..."
                fullWidth
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                error={!!error}
                helperText={error}
                autoComplete="off"
                sx={{ m: '1px' }}
              />
              <IconButton type="submit" color="primary" size="large">
                <SendIcon />
              </IconButton>
            </Box>
          </Box>
        )}

      </Box>
    </Paper>
  );

};

export default Chatbot;