import axios from 'axios';
import { BASE_URL } from './constant';
import { getToken } from './helpers';
import { logout } from './Redux/slices/authSlice';
import { store } from './Redux/store';


// axios.interceptors.request.use(function (config) {
//   config.headers['Authorization'] = 'bearer ' + localStorage.getItem('access_token');
//   return config;
// }, function (error) {
//   // Do something with request error
//   return Promise.reject(error);
// });

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response) {
      const errorStatus = error.response.status || error.response.data?.error?.status;

      if (errorStatus === 401) {
        console.error("Unauthorized - Logging out...");
        store.dispatch(logout());
        localStorage.removeItem("access_token");
        axios.defaults.headers.common["Authorization"] = "";
        
        setTimeout(() => {
          if (window.location.pathname !== "/login") {
            window.location.replace("/login");
          }
        }, 100); 
      } else {
        console.error("API Error:", error.response.data);
      }
    } else if (error.request) {
      console.error("No response received from server:", error.request);
    } else {
      console.error("Error setting up request:", error.message);
    }

    return Promise.reject(error);
  }
);


const makeRequest = async (config, withAuth = true) => {
  const defaultConfig = {
    baseURL: BASE_URL,
    headers: {},
  };
  const authToken = getToken();
  const mergedConfig = { ...defaultConfig, ...config };

  if (withAuth && authToken) {
    mergedConfig.headers["Authorization"] = `Bearer ${authToken}`;
  }

  try {
    const response = await axios(mergedConfig);
    return response.data;
  } catch (error) {
    console.error("Request Error:", error);
    throw error;
  }
};

export default makeRequest;