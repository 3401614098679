import { Box, Container } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { addMessage, updateFormData, submitProduct } from '../../Redux/slices/chatBotSlice';
import Chatbot from './Chatbot';

const Onboarding = () => {
    const dispatch = useDispatch();
    const handleSubmit = async (formData) => {
        try {
            const result = await dispatch(submitProduct(formData)).unwrap();
            return result;
        } catch (error) {
            dispatch(addMessage({
                type: 'bot',
                content: 'Failed to create product. Please try again.'
            }));
        }
    };
    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 8,
                px: 1
            }}
        >
            <Container maxWidth="xl" style={{ justifyContent: 'flex-end' }}>
                <Chatbot onSubmit={handleSubmit} />
            </Container>
        </Box>);
};

export default Onboarding;