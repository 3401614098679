import React from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Box, Paper } from '@mui/material';
import { Can } from '../../context/Can';
import { CHANNEL_ICONS_MAP } from '../../constant';

const ChannelList = ({ channels, handleFacebookConnect }) => {

  return (
    <Box sx={{ marginTop: 4 }}>
      <Can do="manage" on='ConnectFacebook'>
        <Box display="flex" sx={{ justifyContent: 'flex-end' }}>
          <Button variant="contained" color="primary" onClick={() => { handleFacebookConnect() }}>
            Connect Facebook
          </Button>
        </Box>
      </Can>

      <TableContainer component={Paper} sx={{ marginTop: 4 }}>
        {channels.length > 0 ? (<Table>
          <TableHead>
            <TableRow>
              <TableCell align='left'><Typography variant="body1" fontWeight="bold">Channel</Typography></TableCell>
              {/* <TableCell align='center'><Typography variant="body1" fontWeight="bold">Channel Type</Typography></TableCell> */}
              <TableCell align='center'><Typography variant="body1" fontWeight="bold">Linked Products</Typography></TableCell>
            </TableRow>
          </TableHead>
          
          <TableBody>
            {channels.map((channel, index) => (
              <TableRow key={channel?.channel_id}>
                <TableCell align='left'>
                  <div style={{ display: 'flex', alignItems: 'center'}}>
                    {(channel?.channel_image_url) ? (
                      <img width={30} height={30} style={{ marginRight: '5px', borderRadius: '4px' }} src={channel?.channel_image_url} />
                    ) : (
                      <div className='fr-text-profile'>{channel.display_name[0]}</div>
                    )}
                    <div style={{ marginTop: '6px', marginRight: '5px' }} dangerouslySetInnerHTML={{__html: CHANNEL_ICONS_MAP[channel?.channel_type]}} /> {channel?.display_name}
                  </div>
                </TableCell>
                {/* <TableCell align='center'>{channel?.channel_type || '-'}</TableCell> */}
                <TableCell align='center'>{channel?.products?.map(i => i.name).join(', ') || '-'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>) : <Typography variant="body1" fontWeight="bold" sx={{ py: 4, textAlign: 'center' }}>Channels are unavailable. Please connect to Facebook to fetch channels and continue.</Typography>}
      </TableContainer>
    </Box>
  );
};

export default ChannelList;
