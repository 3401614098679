import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import makeRequest from "../../makeRequest";
import { Button, Grid, Box, IconButton, Stack, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Tooltip, Select } from '@mui/material';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import MenuItem from '@mui/material/MenuItem';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useDataContext } from '../../context/DataContext';
import MUILoader from "../../components/MUILoader";
import PageHeader from "../../components/PageHeader";
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import Loader from "../../components/Loader";
import DeleteIcon from '@mui/icons-material/Delete';
import { Can } from "../../context/Can";
import { subject } from "@casl/ability";
import { parseNumber, validateVideoFile, validateImageFile } from "../../helpers";
import { setErrorMessage,clearMessages, setSuccessMessage, setGlobalMessage } from "../../Redux/slices/uiSlice";
import { useDispatch, useSelector } from "react-redux";
import PalleonEditor from "../../components/PalleonEditor";
import MediaSection from "./MediaSection";
import { nowLocal,formatDateWithTimezone } from "../../helpers";


function EditPost({ currentProductId, pId, cardType, triggerClose, pageType  }) {
    const { id } = useParams();
    const postId = id || pId;
    const { channels, postTypes, postStatusList } = useDataContext();
    const slider = useRef();
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [channel, setChannel] = useState('');
    const [type, setType] = useState('');
    const [status, setStatus] = useState("");
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [postMediaData, setPostMediaData] = useState([]);
    const [mediaData, setMediaData] = useState([]);
    const [newMedia, setNewMedia] = useState([]);
    const [jsData, setJsData] = useState(null);
    const [scheduledBy, setScheduledBy] = useState('');
    const [userArray, setUserArray] = useState([]);
    const [content, setContent] = useState("");
    const [imagePrompt, setImagePrompt] = useState("");
    const [scheduledTime, setScheduledTime] = useState(null);
    const [originalChannel, setOriginalChannel] = useState(null);
    const [originalScheduledBy, setOriginalScheduledBy] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const navigate = useNavigate();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const dispatch = useDispatch();
    const [frames, setFrames] = useState([]);
    const [selectedFrame, setSelectedFrame] = useState("");
    const [frameMedia, setFrameMedia] = useState(null);
    const [selectedEditImage, setSelectedEditImage] = useState(null);
    const [selectedEditImageId, setSelectedEditImageId] = useState(null);
    const [templateFile, setTemplateFile] = useState(null);
    const [selectedTemplateId, setSelectedTemplateId] = useState(null);
    const [openPalleonEditor, setOpenPalleonEditor] = useState(false);
    const [provider, setProvider] = useState("ideogram");
    const [templateFiles, setTemplateFiles] = useState([]);
    const [errors,setErrors] = useState(null);
    const socialPlatform = channels.find(c => c.id === channel)?.attributes?.channel_type;

    const formatDateTimeLocal = (isoString) => {
        const date = new Date(isoString);
        const pad = (n) => String(n).padStart(2, '0');
    
        const year = date.getFullYear();
        const month = pad(date.getMonth() + 1);
        const day = pad(date.getDate());
        const hours = pad(date.getHours());
        const minutes = pad(date.getMinutes());
    
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };


    const fetchPostDetails = async () => {
        setLoading(true);
        try {
            const [respData, userResponse] = await Promise.all([
                makeRequest({ method: 'GET', url: `/api/posts/${postId}?populate=*&publicationState=preview&populate=channel.product&populate=media&populate=scheduled_by&populate=templateFile` }),
                makeRequest({ method: 'GET', url: '/api/users' })
            ]);
            setData(respData);
            setTemplateFile(respData.data?.attributes?.templateFile?.data?.attributes?.url || null);
            const channelData = respData.data.attributes.channel?.data?.attributes;
            if (channelData) {
                if (channelData?.product?.data) {
                    const fProd = channelData.product.data;
                    setSelectedProduct(fProd)
                    const fetchedFrames = channelData.product?.data?.attributes?.frames || [];
                    setFrames(fetchedFrames);
                }
                const defaultChannel = {
                    id: respData.data.attributes.channel.data.id,
                    label: `${channelData.display_name} - ${channelData.channel_type}`,
                };
                setChannel(defaultChannel.id); // Set default selected value
                // setChannelArray(prev => [...prev, defaultChannel]);
                // console.log('defaultChannel: ', defaultChannel);
                setOriginalChannel(defaultChannel.id);
            }

            const mediaItems = respData.data.attributes.media?.data?.map(item => ({
                id: item.id,
                img: item.attributes.url,
                createdAt: item.attributes.createdAt,
            })) || [];
            
            const sortedMediaItems = mediaItems.sort(
                (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
              );
            setMediaData(sortedMediaItems);

            const fetchedTemplateFiles = respData.data?.attributes?.templateFile?.data?.map(t => ({
                id: t.id,
                url: t.attributes.url
            })) || [];
            setTemplateFiles(fetchedTemplateFiles);

            const userId = respData.data.attributes.scheduled_by?.data?.id;
            if (userId) {
                const user = userResponse.find((user) => user.id === userId);
                if (user) {
                    // console.log('user: ', user);
                    setScheduledBy(user.id);
                }
                setOriginalScheduledBy(user.id);
            }
            // const type = respData.data.attributes.type;
            // console.log('type: ', type);
            setType(respData.data.attributes.type || "");
            // console.log('respData: ', respData);

            setStatus(respData.data.attributes.status || "");

            // setScheduledBy(respData.data.attributes.scheduled_by.data.attributes.username || "");

            setContent(respData.data.attributes.content || "");
            const jsonData = respData.data.attributes.jsonData;

            if (jsonData) {
                if (jsonData?.image_prompt) {
                    setImagePrompt(jsonData?.image_prompt);
                } 

                if (jsonData.selectedFrame) {
                    setSelectedFrame(jsonData.selectedFrame);
                    setFrameMedia(jsonData.frameMedia);
                }
                setJsData(jsonData);
            }
            // setScheduledTime(dayjs(respData.data.attributes.scheduled_time || dayjs()));
            const scheduledTime = respData.data.attributes.tm_scheduled;
            if (scheduledTime) {
                setScheduledTime(formatDateTimeLocal(scheduledTime));
            }

            // console.log('user response: ', userResponse);
            const allUsers = userResponse.map(user => ({
                id: user.id,
                label: `${user.username}`,
            }));
            // console.log('allUsers: ', allUsers);
            setUserArray(allUsers);

        } catch (error) {
            console.error("Failed to fetch data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!postId) return;
        fetchPostDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postId]);


    const handleChannelChange = (event) => {
        setChannel(event.target.value);
    };

    const handleTypeChange = (event) => {
        setType(event.target.value);
    };

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };

    const handleScheduledByChange = (event) => {
        setScheduledBy(event.target.value);
    };

    const handleContentChange = (event) => {
        setContent(event.target.value);
    };
    const handleImagePromptChange = (event) => {
        setImagePrompt(event.target.value);
    };


    const handleSave = async () => {
        setErrors({});
        const currentTime = new Date();
        const selectedTime = new Date(scheduledTime);
        currentTime.setSeconds(0, 0);
        selectedTime.setSeconds(0, 0);
                
        if (selectedTime.getTime() < currentTime.getTime()) {
          setErrors({ start_date: "Please select a time that is not earlier than the current time" });
          return;
        }
        if (status === "scheduled" && (type === "single-image-post" || type === "single-video-post")) {
            const totalMediaCount = mediaData.length + newMedia.length;
            if (totalMediaCount > 1) {
                dispatch(setGlobalMessage({
                    message: `Only one media is allowed for ${type === 'single-video-post'? 'single video': 'single image'} post.`,
                    severity: "error"
                }));
                return;
            }
        }
    
        let updatedMediaIds = [...mediaData.map(m => m.id)];
        const originalMediaIds = data?.data?.attributes?.media?.data?.map(m => m.id) || [];
        const originalTemplates = data?.data?.attributes?.jsonData?.editedMedia || [];
    
        let existingTemplateIds = [];
        if (data?.data?.attributes?.templateFile?.data?.length) {
            existingTemplateIds = data.data.attributes.templateFile.data.map(t => t.id);
        }
    
        try {
            if (['single-image-post', 'multi-images', 'story-image'].includes(type)) {
                if (newMedia.some(media => media.file?.type.startsWith('video/'))) {
                    dispatch(setGlobalMessage({
                        message: "Video files not allowed. Upload images only!",
                        severity: "error"
                    }));
                    return;
                }
            } else if (['single-video-post', 'multi-videos', 'story-video', 'reels'].includes(type)) {
                if (newMedia.some(media => !media.file?.type.startsWith('video/'))) {
                    dispatch(setGlobalMessage({
                        message: "Only video files allowed!",
                        severity: "error"
                    }));
                    return;
                }
            }
    
            if (type !== 'text' && mediaData.length === 0 && newMedia.length === 0) {
                dispatch(setGlobalMessage({
                    message: "No valid media selected!",
                    severity: "error"
                }));
                return;
            }
            setIsLoading(true);
            const mediaToDelete = originalMediaIds.filter(id => !updatedMediaIds.includes(id));
            const templatesToDelete = originalTemplates
                .filter(entry => mediaToDelete.includes(entry.mediaId))
                .map(entry => entry.templateId);
    
            let updatedTemplateIds = existingTemplateIds.filter(id => !templatesToDelete.includes(id));
    
            let updatedJsonData = {
                ...jsData,
                image_prompt: imagePrompt,
                editedMedia: jsData.editedMedia
                    ? jsData.editedMedia.filter(entry => !mediaToDelete.includes(entry.mediaId))
                    : []
            };
    
            if (newMedia.length > 0) {
                const formData = new FormData();
                newMedia.forEach((media) => formData.append('files', media.file));
    
                const uploadResponse = await makeRequest({
                    method: "POST",
                    url: "/api/upload",
                    data: formData,
                    headers: { "Content-Type": "multipart/form-data" },
                });
    
                updatedMediaIds = [...updatedMediaIds, ...uploadResponse.map(item => item.id)];
            }
    
            if (selectedFrame) {
                updatedJsonData = { ...updatedJsonData, selectedFrame, frameMedia };
            } else {
                const { selectedFrame, frameMedia, ...restJsonData } = updatedJsonData;
                updatedJsonData = restJsonData;
            }
            const scheduledTimeObj = new Date(scheduledTime);
            // const utcStartDate = formatDateToUTC(startDateObj);
            const scheduledTimeZone = formatDateWithTimezone(scheduledTimeObj);
            const dataToUpdate = {
                tm_scheduled: scheduledTimeZone,
                content,
                status,
                media: updatedMediaIds,  
                templateFile: updatedTemplateIds,  
                jsonData: updatedJsonData,
                origin: 'user-action'
            };
    
            const response = await makeRequest({
                method: "PUT",
                url: `/api/posts/${postId}`,
                data: { data: dataToUpdate },
            });
    
            if (response?.data) {
                setOpenSnackbar(true);
                if (cardType) {
                    triggerClose();
                }
            }
    
            if (!cardType) {
                navigate(pageType === 'post-cards' ? '/schedule?view=posts' : '/schedule?view=calendar');
            } else {
                triggerClose();
            }
    
            dispatch(setGlobalMessage({ message: "Post saved successfully!", severity: "success" }));
    
        } catch (error) {
            dispatch(setGlobalMessage({ message: error?.response?.data?.error?.message || "Something went wrong", severity: "error" }));
            setOpenSnackbar(false);
        }
    
        setIsLoading(false);
        setIsSaving(true);
        await new Promise(resolve => setTimeout(resolve, 1000));
        setIsSaving(false);
        setOpenSnackbar(true);
    };
        

    const handleAddMedia = async (event) => {
        dispatch(clearMessages());
        if (!event.target.files || event.target.files.length === 0) return;

        event.persist();
        const inputElement = event.target;
        const files = Array.from(event.target.files);
        const validFiles = [];

        for (const file of files) {
            try {

                if (['single-image-post', 'multi-images', 'story-image'].includes(type)) {
                    const { errors: imageErrors, warnings: imageWarnings } = await validateImageFile(file, type, socialPlatform);
                    if (imageErrors.length > 0) {
                        dispatch(setGlobalMessage({
                            message: `Invalid file ${file.name}: ${imageErrors.join(', ')}`,
                            severity: "error"
                        }));
                        continue;
                    }
                    if (imageWarnings.length > 0) {
                        dispatch(setGlobalMessage({
                            message: `Warning for ${file.name}: ${imageWarnings.join(', ')}`,
                            severity: "warning"
                        }));
                    }
                }

                if (['reels', 'story-video', 'single-video-post', 'multi-videos'].includes(type)) {
                    const errors = await validateVideoFile(file, type, socialPlatform);

                    const hardErrors = errors.filter(e => !e.includes('Recommended'));
                    const warnings = errors.filter(e => e.includes('Recommended'));

                    if (hardErrors.length > 0) {
                        dispatch(setGlobalMessage({
                            message: `Invalid file ${file.name}: ${hardErrors.join(', ')}`,
                            severity: "error"
                        }));
                        continue;
                    }

                    if (warnings.length > 0) {
                        dispatch(setGlobalMessage({
                            message: `Warning for ${file.name}: ${warnings.join(', ')}`,
                            severity: "warning"
                        }));
                    }
                }

                const isImage = file.type.startsWith('image/');
                const isVideo = file.type.startsWith('video/');
                const isAllowedVideoType = ['video/mp4', 'video/quicktime'].includes(file.type);

                if (type === 'single-video-post' || type === 'multi-videos' || type === 'reels' || type === 'story-video') {
                    if (!isVideo || !isAllowedVideoType) {
                        dispatch(setGlobalMessage({
                            message: "Only MP4/MOV videos allowed for this post type",
                            severity: "error"
                        }));
                        continue;
                    }
                } else if (type === 'single-image-post' || type === 'multi-images' || type === 'story-image') {
                    if (!isImage) {
                        dispatch(setGlobalMessage({
                            message: "Only images allowed for this post type",
                            severity: "error"
                        }));
                        continue;
                    }
                }

                if ((type === "single-image-post" || type === "single-video-post") &&
                    (newMedia.length + validFiles.length > 1)) {
                    dispatch(setGlobalMessage({
                        message: "You can only add one media file for this post type",
                        severity: "error"
                    }));
                    break;
                }
                
                validFiles.push(file);

            } catch (error) {
                console.error("Error validating file:", error);
                dispatch(setGlobalMessage({
                    message: `Error processing ${file.name}: ${error.message}`,
                    severity: "error"
                }));
            }
        }

        if (validFiles.length === 0) {
            inputElement.value = '';
            return;
        }

        try {
            const formattedFiles = validFiles.reverse().map((file) => ({
                file,
                img: URL.createObjectURL(file),
                tempId: Date.now() + Math.random(),
            }));

            setNewMedia(prev => [...formattedFiles, ...prev]);

            if (slider.current) {
                setTimeout(() => slider.current.slickGoTo(0), 300);
            }

            inputElement.value = ''; 
        } catch (error) {
            inputElement.value = ''; 
            console.error("Error creating object URLs:", error);
            dispatch(setGlobalMessage({
                message: "Error processing selected files",
                severity: "error"
            }));
        }
    };

    const handleDeleteMedia = (dItem) => {
        if (dItem.tempId) {
            setNewMedia(prev => prev.filter(item => item.tempId !== dItem.tempId));
        } else if (dItem.id) {
            setMediaData(prev => prev.filter(item => item.id !== dItem.id));
            setPostMediaData(prev => prev.filter(item => item.id !== dItem.id));
            setJsData(prevJsonData => {
                if (!prevJsonData) return prevJsonData;
                const updatedJsonData = { ...prevJsonData };
                if (updatedJsonData.editedMedia) {
                    updatedJsonData.editedMedia = updatedJsonData.editedMedia.filter(entry => entry.mediaId !== dItem.id);
                }
                return updatedJsonData;
            });
            setTemplateFiles(prevTemplates => prevTemplates.filter(t => {
                const relatedTemplate = jsData?.editedMedia?.find(e => e.mediaId === dItem.id);
                return relatedTemplate ? t.id !== relatedTemplate.templateId : true;
            }));
        }
    };


    const handleCloseSnackbar = () => {
        dispatch(clearMessages());
        setOpenSnackbar(false);
    };

    const handleGenerateImage = async () => {
        try {
            if (!selectedProduct) {
                console.log('No selectedProduct: ');
                return
            }
            setIsLoading(true);
            const response = await makeRequest({
                method: "POST",
                url: `/api/chatgpt/generate-image`,
                data: {
                    postId,
                    product: selectedProduct?.id,
                    imagePrompt,
                    provider,
                },
            });
            // console.log('Image Generate response: ', response);

            if (response?.data?.updatedPost?.media?.length > 0) {
                const generatedMedia = response.data.updatedPost.media.map(mediaItem => ({
                    id: mediaItem.id,
                    img: mediaItem.url,
                    createdAt: mediaItem.createdAt,
                }));

                setMediaData((prev) => {
                    const existingIds = new Set(prev.map(item => item.id));
                    const newItems = generatedMedia.filter(item => !existingIds.has(item.id));
                    return [...newItems, ...prev];
                });
                
                setTimeout(() => {
                    if (slider.current) {
                        slider.current.slickGoTo(0);
                    }
                }, 300);
            }
            dispatch(setGlobalMessage({message:"Image generated successfully!",severity:"success"}));
        } catch (error) {
            dispatch(setGlobalMessage({message:"Unable to generate image",severity:"error"}));
            // console.error("Error saving post:", error);
        }
        setIsLoading(false);
    }

    const handleEditImageOnSave = async (imageFile, jsonFile) => {
        try {
            if (!imageFile || !jsonFile) {
                console.error("Missing image or template file");
                return;
            }

            setIsLoading(true);
            const formData = new FormData();
            formData.append("postId", postId);
            formData.append("image", imageFile, `${postId}_edited_image_${Date.now()}.png`);
            formData.append("template", jsonFile, `${postId}_template_${Date.now()}.json`);

            if (selectedEditImageId) {
                formData.append("oldMediaId", selectedEditImageId);
            }

            if (selectedTemplateId) {
                formData.append("oldTemplateId", selectedTemplateId);
            }

            const response = await makeRequest({
                method: "POST",
                url: `/api/posts/save-edited-image`,
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            });

            if (response?.data) {
                console.log("Updated post:", response.data);
                handlePalleonEditorClose();
                fetchPostDetails();
            }

            dispatch(setGlobalMessage({ message: "Image and template saved successfully!", severity: "success" }));
        } catch (error) {
            console.error("Error saving edited image and template:", error);
            dispatch(setGlobalMessage({ message: "Something went wrong", severity: "error" }));
        } finally {
            setIsLoading(false);
        }
    };

    const handleImageEdit = (imgItem) => {
        const mediaId = imgItem.id;
        setSelectedEditImageId(mediaId);
        const jsonData = data?.data?.attributes?.jsonData || {};
        const templateEntry = jsonData.editedMedia?.find(e => e.mediaId === mediaId);
        const templateId = templateEntry?.templateId || null;
        const templateUrl = templateId
            ? templateFiles.find(t => t.id === templateId)?.url
            : null;
        setSelectedTemplateId(templateId);
        setSelectedEditImage(imgItem.img);
        setTemplateFile(templateUrl);
        setOpenPalleonEditor(true);
    };

    const handlePalleonEditorClose = () => {
        setSelectedEditImage(null)
        setSelectedTemplateId(null);
        setSelectedEditImageId(null);
        setOpenPalleonEditor(false)
    }

    if (loading) {
        return <Loader />; 
    }

    const channelOptions = channels.map(channelItem => {
        return ({
            id: channelItem.id,
            label: `${channelItem.display_name} - ${channelItem.channel_type}`,
        })
    });

    const handleDelete = async (postId) => {
        console.log('postId: ', postId);
        setIsLoading(true);
        try {
            await makeRequest({
                method: 'DELETE',
                url: `/api/posts/${postId}`,
            });
            dispatch(setGlobalMessage({ message: "Post deleted successfully!", severity: "success" }));
            navigate('/schedule?view=calendar');
        } catch (error) {
            console.error('Error deleting post:', error);
            // alert("An error occurred while deleting the post. Please try again.");
            dispatch(setGlobalMessage({message:"An error occurred while deleting the post. Please try again.",severity:"error"}));
        
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeleteClick = () => {
        setOpenDeleteDialog(true);
    };

    const handleDeleteConfirm = () => {
        handleDelete(postId);
        setOpenDeleteDialog(false);
    };

    const handleDeleteCancel = () => {
        setOpenDeleteDialog(false);
    };

    const handleFrameChange = (e) => {
        const frameId = e.target.value;
        setSelectedFrame(frameId);
        const frame = frames.find(f => f.id === frameId);
        setFrameMedia(frame?.media?.data?.attributes?.url || "");
    };

    const handleClearFrame = () => {
        setSelectedFrame("");
        setFrameMedia(null);
    };


    return (
        <Box
            position="relative"
            component="main"
            sx={{
                flexGrow: 1,
                py: pId ? 1 : 8,
                px: 1
            }}>

            <Container maxWidth="xl">
                {!pId && <PageHeader title="Edit Post" icon={<EditCalendarIcon />}
                    rightContent={
                        <Can
                            I="manage"
                            this={subject("ManagePosts", { productId: parseNumber(currentProductId) })}
                            field="productId"
                        >
                            <Button
                                color="error"
                                variant="contained"
                                size='small'
                                onClick={handleDeleteClick}
                                startIcon={<DeleteIcon />}
                            >
                                Delete
                            </Button>
                        </Can>
                    } 
                    />}

                <Dialog open={openDeleteDialog} onClose={handleDeleteCancel}>
                    <DialogTitle>Confirm Delete</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete this post? This action cannot be undone.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={handleDeleteCancel}>Cancel</Button>
                        <Can
                            I="manage"
                            this={subject("ManagePosts", { productId: parseNumber(currentProductId) })}
                            field="productId"
                        >
                            <Button variant="outlined" onClick={handleDeleteConfirm} color="error" disabled={isLoading}>
                                {isLoading ? "Deleting..." : "Delete"}
                            </Button>
                        </Can>
                    </DialogActions>
                </Dialog>
                <Box sx={{
                    mt: pId ? 1 : 4,
                    flexGrow: 1
                }}>

                    {isLoading && <MUILoader open />}
                    <Grid container spacing={2} >
                        <Grid item xs={12} md={6}>
                            <Stack spacing={2}>
                                <TextField
                                    select
                                    fullWidth
                                    value={channel}
                                    label="Channel"
                                    disabled={true}
                                    // onChange={handleChannelChange}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                >
                                    {channelOptions.map((channel) => (
                                        <MenuItem key={channel.id} value={channel.id}>
                                            {channel.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    // select
                                    value={type}
                                    label="Type"
                                    fullWidth
                                    disabled={true}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                >
                                    {postTypes.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker
                                        label="Scheduled Time"
                                        value={scheduledTime}
                                        onChange={(newValue) => setScheduledTime(newValue)}
                                        sx={{ width: '100%' }}
                                    />
                                </LocalizationProvider> */}
                                <TextField
                                    label="Scheduled Time"
                                    type="datetime-local"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        min: nowLocal,
                                    }}
                                    value={scheduledTime || ""}
                                    onChange={(e) => {setScheduledTime(e.target.value);setErrors({})}}
                                    required
                                    FormHelperTextProps={{ error: Boolean(errors?.start_date), sx: { marginLeft: '0px' } }}
                                    helperText={errors?.start_date}
                                />
                                {data && (
                                    <TextField
                                        id="outlined-multiline-static"
                                        label="Content"
                                        multiline
                                        rows={6.7}
                                        value={content}
                                        onChange={handleContentChange}
                                        fullWidth
                                    />
                                )}
                                <TextField
                                    select
                                    value={status}
                                    label="Status"
                                    fullWidth
                                    onChange={handleStatusChange}
                                >
                                    {postStatusList
                                        .filter(option => option.value !== "published" && option.value !== "error")
                                        .map(option => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                </TextField>
                                <TextField
                                    select
                                    value={scheduledBy}
                                    label="Scheduled By"
                                    fullWidth
                                    disabled={true}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                >
                                    {userArray.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Stack spacing={2}>
                                {data && !(['text','single-video-post', 'multi-videos', 'story-video', 'reels'].includes(type)) && (
                                    <>
                                        <TextField
                                            id="outlined-multiline-static"
                                            label="Image Prompt"
                                            multiline
                                            rows={4}
                                            value={imagePrompt}
                                            onChange={handleImagePromptChange}
                                            fullWidth
                                        />
                                        {data && (
                                            <Can
                                                I="manage"
                                                this={subject("ManagePosts", { productId: parseNumber(currentProductId) })}
                                                field="productId"
                                            >
                                                <Box mt={1}>
                                                        <Stack direction="row" justifyContent="space-between" spacing={2}>
                                                        <TextField
                                                            select
                                                            label="Image Provider"
                                                            value={provider}
                                                            onChange={(e) => setProvider(e.target.value)}
                                                            sx={{
                                                                width:200
                                                            }}
                                                        >
                                                            <MenuItem value="dalle">DALL·E</MenuItem>
                                                            {/* <MenuItem value="gemini">Gemini</MenuItem> */}
                                                            <MenuItem value="ideogram">Ideogram.ai</MenuItem>
                                                        </TextField>
                                                        <Button onClick={handleGenerateImage} disabled={!imagePrompt}>
                                                            Generate Image
                                                        </Button>
                                                        </Stack>
                                                </Box>
                                            </Can>
                                        )}
                                    </>
                                )}
                                {type !== 'text' && (
                                    <MediaSection
                                        type={type}
                                        mediaData={mediaData}
                                        newMedia={newMedia}
                                        onAddMedia={handleAddMedia}
                                        onDeleteMedia={handleDeleteMedia}
                                        frames={frames}
                                        frameMedia={frameMedia}
                                        selectedFrame={selectedFrame}
                                        onFrameChange={handleFrameChange}
                                        onClearFrame={handleClearFrame}
                                        sliderRef={slider}
                                        showEditImage
                                        onImageEdit={handleImageEdit}
                                        disabled={!channel}
                                    />

                                )}
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={12} >
                            <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent={pId? 'space-between': 'flex-end'}
                                >
                                {pId &&
                                    <Can
                                        I="manage"
                                        this={subject("ManagePosts", { productId: parseNumber(currentProductId) })}
                                        field="productId"
                                    >
                                        <Button
                                            color="error"
                                            variant="contained"
                                            size='small'
                                            onClick={handleDeleteClick}
                                        // startIcon={<DeleteIcon />}
                                        >
                                            Delete
                                        </Button>
                                    </Can>}
                                <Can
                                    I="manage"
                                    this={subject("ManagePosts", { productId: parseNumber(currentProductId) })}
                                    field="productId"
                                >
                                    <Button variant="contained" color="primary" onClick={handleSave}>
                                    {isSaving ? 'Saving...' : 'Save'}
                                    </Button>
                                </Can>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>

            {
                openPalleonEditor &&
                    <PalleonEditor
                        imgSrc={selectedEditImage}
                        templateJson={templateFile}
                        onClose={handlePalleonEditorClose}
                        handleSubmit={handleEditImageOnSave}
                    />
            }
        </Box>
    );
}

export default EditPost; 
