import React,{ useEffect } from 'react';
import Tabs from '../../components/Tabs';
import PostList from './CardView';
import CalendarComponent from '../../components/calendar';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Button, SvgIcon } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Can } from '../../context/Can';
import { subject } from "@casl/ability";
import { parseNumber } from '../../helpers';

function Schedule({ productId }) {
    const navigate = useNavigate();
    const location = useLocation();
    const [queryParams, setQueryParams] = useSearchParams();
    const existingParams = Object.fromEntries([...queryParams]);
    const view = existingParams?.view || '';
    const tabs = [
        { key: 'calendar', label: 'Calendar', content: <CalendarComponent productId={productId}/> },
        { key: 'posts', label: 'Posts', content: <PostList productId={productId}/> },
    ];
    const activeTab = location.pathname.includes('posts') ? 'posts' : 'calendar';

    useEffect(() => {
        if (view === 'calendar' && queryParams.has('page')) {
            const updatedParams = { ...existingParams };
            delete updatedParams.page;
            setQueryParams(updatedParams);
        }
    }, [view, queryParams, setQueryParams, existingParams]);
    
    return (
        <Tabs
            tabs={tabs}
            defaultView={activeTab}
            showPageHeader
            pageTitle="Schedule"
            pageIcon={<CalendarMonthIcon />}
            pageHeadRight={
            <Can
                I='manage'
                this={subject('ManagePosts', { productId: parseNumber(productId) })}
                field='productId'
            >
                <Button
                    startIcon={(
                        <SvgIcon fontSize="small">
                            <AddIcon />
                        </SvgIcon>
                    )}
                    variant="contained"
                    onClick={() => { navigate(`/schedule/create-post`); }}
                    size='small'
                >
                    Create Post
                </Button>
                </Can>
            }
        />
    );
}

export default Schedule;
