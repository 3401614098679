import React, { useEffect, useRef, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { palleonBaseURL, SAMPLE_JSON_TEMPLATE } from "../constant";

const dataURLtoBlob = (dataURL) => {
    const byteString = atob(dataURL.split(',')[1]);
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([arrayBuffer], { type: mimeString });
};

const PalleonEditor = ({ imgSrc, templateJson = null, handleSubmit, onClose }) => {
    const iframeRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    const [imageFile, setImageFile] = useState(null);
    const [jsonFile, setJsonFile] = useState(null);
    const [jsonContent, setJsonContent] = useState(null);
    const [isSampleTemplate, setIsSampleTemplate] = useState(false);

    const loadIframe = () => {
        if (iframeRef.current && jsonContent) {
            console.log('Loading iframe with template:', jsonContent);
            iframeRef.current.contentWindow.postMessage(
                { 
                    type: "SET_IMAGE", 
                    payload: { imgSrc, template: jsonContent, isSampleTemplate } 
                },
                "*"
            );
        }
    }

    useEffect(() => {
        const fetchJson = async () => {
            try {
                if (templateJson) {
                    const response = await fetch(templateJson);
                    const data = await response.json();
                    setJsonContent(data);
                    setIsSampleTemplate(false);
                } else {
                    setJsonContent(SAMPLE_JSON_TEMPLATE);
                    setIsSampleTemplate(true);
                }
            } catch (error) {
                console.error("Error fetching template JSON:", error);
                setJsonContent(SAMPLE_JSON_TEMPLATE);
                setIsSampleTemplate(true);
            } finally {
                setIsLoading(false);
            }
        };

        fetchJson();
    }, [templateJson]);

    // useEffect(() => {
    //     if (!isLoading && iframeRef.current && jsonContent) {
    //         console.log("Loading iframe with template:", jsonContent);
    //         iframeRef.current.contentWindow.postMessage(
    //             {
    //                 type: "SET_IMAGE",
    //                 payload: { imgSrc, template: jsonContent, isSampleTemplate }
    //             },
    //             "*"
    //         );
    //     }
    // }, [isLoading, jsonContent]);

    useEffect(() => {
        const handleMessage = (event) => {
            if (event.origin !== window.location.origin) return;
            console.log("Message from iframe:", event.data);

            if (event.data?.type === "IMAGE_SAVED") {
                const { name, imgData, format } = event.data.payload;
                const blob = dataURLtoBlob(imgData);
                const file = new File([blob], name, { type: "image/png" });
                setImageFile(file);
            }

            if (event.data?.type === "TEMPLATE_SAVED") {
                const { fileName, fileData } = event.data.payload;
                const blob = new Blob([fileData], { type: "application/json" });
                const file = new File([blob], fileName, { type: "application/json" });
                setJsonFile(file);
            }

            if (event.data?.type === "CLOSE_IFRAME") {
                if (onClose) onClose();
            }
        };

        window.addEventListener("message", handleMessage);
        return () => window.removeEventListener("message", handleMessage);
    }, []);

    useEffect(() => {
        if (imageFile && jsonFile) {
            handleSubmit(imageFile, jsonFile);
        }
    }, [imageFile, jsonFile]);

    return (
        <Box position="fixed" top={0} left={0} right={0} bottom={0} zIndex={9999} bgcolor="rgba(0,0,0,1)" sx={{ width: "100%", height: "100%", display: "block" }}>
            <Box position="relative" width="100%" height="100vh">
                {isLoading && (
                    <Box position="absolute" top="50%" left="50%" sx={{ transform: "translate(-50%, -50%)" }}>
                        <CircularProgress />
                    </Box>
                )}
                {jsonContent && (
                    <iframe
                        ref={iframeRef}
                        title="Palleon Editor"
                        src={palleonBaseURL}
                        width="100%"
                        height="100vh"
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            border: "none",
                            display: isLoading ? "none" : "block"
                        }}
                        onLoad={loadIframe}
                    />
                )}
            </Box>
        </Box>
    );
};

export default PalleonEditor;
