import React from 'react'
import { IconButton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

function PrevArrow({ onClick, disabled, sx = {} }) {
    return (
        !disabled && <IconButton
            onClick={onClick}
            disabled={disabled}
            sx={{
                position: 'absolute',
                top: '50%',
                left: '0px',
                transform: 'translateY(-50%)',
                color: '#FFF',
                backgroundColor: 'rgba(0,0,0,0.5) !important',
                zIndex: 1,
                ...sx
            }}
        >
            <ChevronLeftIcon />
        </IconButton>
    );
}

export default PrevArrow;