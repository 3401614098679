import { Container, Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PageHeader from '../../components/PageHeader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Grid from '@mui/material/Unstable_Grid2';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CommentIcon from '@mui/icons-material/Comment';
import Insights from './Insights';
import ProductChannelStats from './ProductChannelStats';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ProductPostStats from './ProductPostStats';
import { CountCard } from './CountCard';
import GroupedCard from './GroupedCard';
import LikesCommentChart from './LikesCommentChart';
import makeRequest from '../../../src/makeRequest';
import { useSelector } from 'react-redux';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { CountCardIcons } from './CountCardIcons';

function Dashboard({ productId }) {
    const [totalPosts, setTotalPosts] = useState(0);
    const [totalComments, setTotalComments] = useState(0);
    const [totalLikes, setTotalLikes] = useState(0);
    const [productStats, setProductStats] = useState([]);
    const [dynamicCardLoading, setDynamicCardLoading] = useState(true);
    const [loadingPosts, setLoadingPosts] = useState(true);
    const [loadingComments, setLoadingComments] = useState(true);
    const [loadingLikes, setLoadingLikes] = useState(true);
    const [groupedCardLoading, setGroupedCardLoading] = useState(true);
    const [groupedCardData, setGroupedCardData] = useState([]);
    const [productCard, setProductCard] = useState([]);
    const [productFollowerCount, setProductFollowerCount] = useState([]);
    // const dataState = useSelector(state=>state.data);
    const [channelLikes, setChannelLikes] = useState({}); // Store likes per channel

    const fetchAllPostCounts = async () => {
        try {
            const allPostCountRes = await makeRequest({
                url: `/api/post/all-post-counts`,
                method: 'GET',
            });

            if (allPostCountRes) {
                const publishedPostCounts = allPostCountRes.productChannelCounts.map(product => ({
                    productId: product.productId,
                    productName: product.productName,
                    totalPosts: product.channels.reduce((sum, channel) => {
                        return sum + (channel.postCounts.publishedEntries || 0);
                    }, 0),
                }));
                setTotalPosts(allPostCountRes?.groupedStatusCounts?.publishedEntries || 0);
                setProductStats(publishedPostCounts);
                setGroupedCardData(allPostCountRes);
            }
        } catch (error) {
            console.error('Failed to fetch all post count:', error);
        } finally {
            setLoadingPosts(false);
            setGroupedCardLoading(false);
            setDynamicCardLoading(false);
        }
    };

    const fetchGroupedPostStats = async () => {
        try {
            const likeCommentCount = await makeRequest({
                url: `/api/posts/like-comment-counts?publicationState=preview`,
                method: 'GET',
            });
            if (likeCommentCount) {
                setTotalLikes(likeCommentCount.total_likes || 0);
                setTotalComments(likeCommentCount.total_comments || 0);
                setProductCard(likeCommentCount);
            } else {
                setTotalLikes(0);
                setTotalComments(0);
            }
        } catch (error) {
            console.error('Error fetching grouped post stats:', error);
        } finally {
            setLoadingLikes(false);
            setLoadingComments(false);
        }
    };
    const fetchAllFollowersCount = async () => {
        try {
            const followerCount = await makeRequest({
                url: `/api/product/follower-counts?publicationState=preview`,
                method: 'GET',
            });
            if (followerCount?.products) {
                setProductFollowerCount(followerCount.products);
            }
        } catch (error) {
            console.error('Error fetching follower counts:', error);
        }
    };

    useEffect(() => {
        fetchAllPostCounts();
        fetchGroupedPostStats();
        fetchAllFollowersCount();
    }, []);

    const cardData = [
        {
            loading: loadingPosts,
            title: 'Total Posts',
            value: `${totalPosts || 0}`,
            icon: <FavoriteIcon color="primary" />,
        },
        {
            loading: loadingLikes,
            title: 'Total Likes',
            value: `${totalLikes || 0}`,
            icon: <CommentIcon color="secondary" />,
        },
        {
            loading: loadingComments,
            title: 'Total Comments',
            value: `${totalComments || 0}`,
            icon: <CommentIcon color="secondary" />,
        },
    ];

    const selectedProductCard = (productId) => {
        if (
            !productId ||
            !groupedCardData?.productChannelCounts ||
            !productCard?.products ||
            !productFollowerCount 
        ) {
            return [];
        }

        const product = groupedCardData.productChannelCounts.find(item => item.productId == productId);
        const productStats = productCard.products.find(item => item.product_id == productId);
        const followerStats = productFollowerCount.find(item => item.productId == productId);
        
        const getIcon = (channelType) => {
            if (channelType === 'facebook_page') return <FacebookIcon color="primary" />;
            if (channelType === 'instagram_page') return <InstagramIcon color="secondary" />;
            return null;
        };

        const channelPosts = product?.channels?.reduce((acc, channel) => {
            acc[channel.display_name] = { count: channel.totalPosts || 0, icon: getIcon(channel.channel_type) };
            return acc;
        }, {});
        const totalProductPosts = Object.values(channelPosts || {}).reduce((sum, { count }) => sum + count, 0);
    
        const channelLikes = productStats?.channels?.reduce((acc, channel) => {
            acc[channel.channel_name] = { count: channel.total_channel_likes || 0, icon: getIcon(channel.channel_type) };
            return acc;
        }, {});
        const totalLikes = Object.values(channelLikes || {}).reduce((sum, { count }) => sum + count, 0);
    
        const channelComments = productStats?.channels?.reduce((acc, channel) => {
            acc[channel.channel_name] = { count: channel.total_channel_comments || 0, icon: getIcon(channel.channel_type) };
            return acc;
        }, {});
        const totalComments = Object.values(channelComments || {}).reduce((sum, { count }) => sum + count, 0);
    
        const channelFollowers = followerStats?.channels?.reduce((acc, channel) => {
            acc[channel.channelName] = { count: channel.channelFollowers || 0, icon: getIcon(channel.channel_type) };
            return acc;
        }, {});
        const totalFollowers = Object.values(channelFollowers || {}).reduce((sum, { count }) => sum + count, 0);
    
        return [
            {
                title: 'Posts',
                value: totalProductPosts || 0,
                iconSection: channelPosts || {},
                loading: dynamicCardLoading,
            },
            {
                title: 'Likes',
                value: totalLikes || 0,
                iconSection: channelLikes || {},
                loading: loadingLikes,
            },
            {
                title: 'Comments',
                value: totalComments || 0,
                iconSection: channelComments || {},
                loading: loadingComments,
            },
            {
                title: 'Followers',
                value: totalFollowers || 0,
                iconSection: channelFollowers || {},
                loading: loadingComments,
            },
        ];
    };
    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 8,
                px: 1,
            }}
        >
            <Container maxWidth="xl">
                <PageHeader title="Dashboard" icon={<DashboardIcon />} />

                <Grid container spacing={1} sx={{ mt: 2 }}>
                    {productId
                        ? selectedProductCard(productId).map((card, index) => (
                            <Grid key={`selected-card-${index}`} xs={12} sm={6} lg={3}>
                                <CountCardIcons
                                    title={card.title}
                                    value={card.value}
                                    iconSection={card.iconSection}
                                    loading={card.loading}
                                />
                            </Grid>
                        ))
                        : cardData.map((card, index) => (
                            <Grid key={`general-card-${index}`} xs={12} sm={6} lg={3}>
                                <CountCard
                                    sx={{ height: '100%' }}
                                    title={card.title}
                                    value={card.value}
                                    loading={card.loading}
                                />
                            </Grid>
                        ))}
                </Grid>

                {productId && (
                    <>
                        <Box sx={{ mt: 4 }}>
                            <Grid xs={12} md={6} lg={8}>
                                <ProductChannelStats productId={productId} />
                            </Grid>
                        </Box>
                        <Box sx={{ mt: 4 }}>
                            <Grid xs={12} md={6} lg={8}>
                                <ProductPostStats productId={productId} />
                            </Grid>
                        </Box>
                        <Box sx={{ mt: 4 }}>
                            <Grid xs={12} md={6} lg={8}>
                                <LikesCommentChart productId={productId} />
                            </Grid>
                        </Box>
                    </>
                )}

                {!productId && (
                    <Box sx={{ mt: 4 }}>
                        <Grid xs={12} md={6} lg={8}>
                            <Insights />
                        </Grid>
                    </Box>
                )}
            </Container>
        </Box>
    );
}

export default Dashboard;
