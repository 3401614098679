import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Form, Input, Button, message, Card } from "antd";
import axios from "axios";
import { setGlobalMessage } from "../../Redux/slices/uiSlice";
import { useDispatch } from "react-redux";
import { Link,useNavigate } from "react-router-dom";
import { PASSWORD_PATTERN } from "../../constant";

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleResetPassword = async () => {
    if (!code) {
      message.error("Invalid or missing reset token.");
      return;
    }

    setIsLoading(true);

    try {
      await axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/reset-password`, {
        code,
        password,
        passwordConfirmation: password,
      });
    //   dispatch(setGlobalMessage({ message: "Password reset successful! You can now log in.", severity: "success" }));
      message.success("Password reset successful! You can now log in.");
      setPassword("");
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    } catch (error) {
      console.error("Error resetting password:", error);
    //   dispatch(setGlobalMessage({ message: "Failed to reset password. Please try again.", severity: "error" }));
      message.error(error.response?.data?.error?.message || "Failed to reset password. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card title="Reset Password" style={{ maxWidth: 400, margin: "auto", marginTop: 50 }}>
      <Form name="reset-password-form" layout="vertical" onFinish={handleResetPassword} validateTrigger={['onSubmit', 'onChange']}>
        <Form.Item
          label="New Password"
          name='password'
          rules={[
            { required: true, message: "Password is required" },
            { min: 6, message: "Password must be at least 6 characters" },
            { pattern: PASSWORD_PATTERN, message: "Require a mix of uppercase, lowercase, number, and special character." }
          ]}
        >
          <Input.Password
            placeholder="Enter new password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading} disabled={isLoading} block>
            Reset Password
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default ResetPassword;
