import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import storage from "redux-persist/lib/storage";
import { clearDataState } from './dataSlice';

const token = localStorage.getItem('access_token');

const initialState = {
    isAuthenticated: token ? true : false,
    token:null,
    user: null,
    // user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
    productId: null
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginSuccess(state, action) {
            state.isAuthenticated = true;
            state.token = action.payload.token;
            state.user = action.payload.user;
            state.productId = action.payload.productId
            localStorage.setItem('access_token', action.payload.token);
            localStorage.setItem('user', JSON.stringify(action.payload.user));
        },
        logout(state) {
            localStorage.removeItem('access_token');
            localStorage.removeItem('user');
            storage.removeItem("persist:auth");
            state.isAuthenticated = false;
            state.token = null;
            state.user = null;
            state.productId = null;
            // return state;
        },
        setUser(state, action) {
            state.user = action.payload;
        },
        setProductId(state, action){
            state.productId = action.payload;
        },
        addProductToUser(state, action) {
            if (state.user) {
                state.user.userRoles = [
                    ...state.user.userRoles,
                    {
                        id: action.payload.role.id,
                        role: action.payload.role.role,
                        invitationStatus: action.payload.role.invitationStatus,
                        product: {
                            id: action.payload.id,
                            name: action.payload.name,
                            details: action.payload.details,
                            url: action.payload.url
                        }
                    }
                ];
                localStorage.setItem('user', JSON.stringify(state.user));
            }
        }
    },
});

export const logoutUser = createAsyncThunk('auth/logoutUser', async (_, { dispatch }) => {
    dispatch(logout());  
    dispatch(clearDataState()); 
});

export const { loginSuccess, logout, setUser, setProductId, addProductToUser } = authSlice.actions;
export default authSlice.reducer;
