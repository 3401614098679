import { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

export function useScrollToTop() {
    const { pathname } = useLocation();
    const [searchParams] = useSearchParams();
    const page = searchParams.get('page');
    const pageSize = searchParams.get('pageSize');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname, page, pageSize]);

    return null;
}
