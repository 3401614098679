import PropTypes from 'prop-types';
import { Avatar, Card, CardContent, CircularProgress, Stack, SvgIcon, Typography } from '@mui/material';

export const CountCard = (props) => {
  const { loading, difference, positive = false, sx, value, title} = props;

  return (
    <Card sx={{ ...sx, height: 140, width: 500, position: 'relative' }}>
                <CardContent sx={{ height: '90%', display: 'flex', flexDirection: 'column' }}>
                {/* <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        > */}
          {/* <Stack spacing={1}> */}
            <Typography
              color="text.secondary"
              variant="overline"
            >
              {title}
            </Typography>
            {loading ? <CircularProgress size={20} />
            : <Typography variant="h4">
              {value}
            </Typography>}
          {/* </Stack> */}
      </CardContent>
    </Card>
  );
};

CountCard.propTypes = {
  difference: PropTypes.number,
  positive: PropTypes.bool,
  value: PropTypes.string.isRequired,
  sx: PropTypes.object
};

