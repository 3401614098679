export const AVATAR_API = "https://ui-avatars.com/api";
// export const API = "http://localhost:1337/api";
//export const API = "https://marketing-admin.dev.ceegees-inc.com/api";
export const AUTH_TOKEN = 'access_token' //"authToken";
export const BEARER = "Bearer";
// export const API_TOKEN ="4c8f7908dafb851308f27612d24c32db5bb8cc5bef42a3e45763da6163aed64f31c0722888cf686a01d3dcaf38e8300f950f9985557c00416988ef6e79f697e7f2573da3c75fa33895cb6721da516c02d3d6fd407b6d09058defb47a8296d73f04526d7db7478f71fb85c8336af91449834d5a32c26fc9b03a68651c01b716bc";
//server
//export const API_TOKEN ="af1983a68e18ea400996280125b262f259c967fd4bb583707a8fcd18e092a0c3aced41123150ffe0333418824e0eb8ba2859a86691f5f026ce66401cc31e297f9892e183f7fe6b2d19dc6f5ae37db6f6fb82f0bba5935d4b58b9729e6ef9bf446dada72b3c7723c435e9a178f4e1a2755fc97a015727a316a233acf76560f728";
export const BASE_URL = window.BASE_URL;
export const FB_APP = window.FB_APP;
export const palleonBaseURL = window.PALLEON_BASE_URL;

export const userRoles = {
    // SUPER_ADMIN: 'Super Admin',
    // ADMIN: 'Admin',
    OWNER: 'Owner',
    MANAGER: 'Manager',
    ANALYST: 'Analyst',
}

//sample json template edit image in palleon
export const SAMPLE_JSON_TEMPLATE = {
    "version": "5.4.1",
    "objects": [],
    "background": "transparent",
    "backgroundImage": {
      "type": "image",
      "version": "5.4.1",
      "originX": "left",
      "originY": "top",
      "left": 0,
      "top": 0,
      "width": 800,
      "height": 800,
      "fill": "rgb(0,0,0)",
      "stroke": null,
      "strokeWidth": 0,
      "strokeDashArray": null,
      "strokeLineCap": "butt",
      "strokeDashOffset": 0,
      "strokeLineJoin": "miter",
      "strokeUniform": true,
      "strokeMiterLimit": 4,
      "scaleX": 1,
      "scaleY": 1,
      "angle": 0,
      "flipX": false,
      "flipY": false,
      "opacity": 1,
      "shadow": null,
      "visible": true,
      "backgroundColor": "",
      "fillRule": "nonzero",
      "paintFirst": "fill",
      "globalCompositeOperation": "source-over",
      "skewX": 0,
      "skewY": 0,
      "erasable": true,
      "cropX": 0,
      "cropY": 0,
      "objectType": "BG",
      "mode": "canvas",
      "selectable": false,
      "lockMovementX": true,
      "lockMovementY": true,
      "lockRotation": true,
      "crossOrigin": null,
      "src": "",
      "filters": []
    }
  }

export const EMAIL_PATTERN = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const PASSWORD_PATTERN = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;

export const CHANNEL_ICONS_MAP = {
  facebook_page: `<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="#1877F2"><path d="M22.675 0h-21.35c-0.732 0-1.325 0.593-1.325 1.325v21.351c0 0.731 0.593 1.324 1.325 1.324h11.494v-9.293h-3.125v-3.622h3.125v-2.671c0-3.1 1.893-4.788 4.657-4.788 1.325 0 2.463 0.098 2.795 0.142v3.24l-1.918 0.001c-1.505 0-1.797 0.716-1.797 1.767v2.309h3.59l-0.467 3.622h-3.123v9.293h6.125c0.73 0 1.325-0.593 1.325-1.324v-21.351c0-0.732-0.595-1.325-1.325-1.325z"/></svg>`,
  instagram_page: `<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="#E4405F"><path d="M12 2.2c3.2 0 3.584 0.012 4.849 0.07 3.25.147 4.771 1.688 4.918 4.918.058 1.265.07 1.648.07 4.849s-.012 3.584-.07 4.849c-.147 3.23-1.668 4.771-4.918 4.918-1.265.058-1.648.07-4.849.07s-3.584-.012-4.849-.07c-3.25-.147-4.771-1.688-4.918-4.918-.058-1.265-.07-1.648-.07-4.849s.012-3.584.07-4.849c.147-3.23 1.668-4.771 4.918-4.918 1.265-.058 1.648-.07 4.849-.07m0-2.2c-3.271 0-3.667.012-4.947.071-4.354.198-6.697 2.541-6.895 6.895-.059 1.28-.071 1.676-.071 4.947s.012 3.667.071 4.947c.198 4.354 2.541 6.697 6.895 6.895 1.28.059 1.676.071 4.947.071s3.667-.012 4.947-.071c4.354-.198 6.697-2.541 6.895-6.895.059-1.28.071-1.676.071-4.947s-.012-3.667-.071-4.947c-.198-4.354-2.541-6.697-6.895-6.895-1.28-.059-1.676-.071-4.947-.071zm0 5.4a6.6 6.6 0 1 0 6.6 6.6 6.608 6.608 0 0 0-6.6-6.6zm0 10.8a4.2 4.2 0 1 1 4.2-4.2 4.205 4.205 0 0 1-4.2 4.2zm7.2-11.4a1.32 1.32 0 1 0 1.32 1.32 1.318 1.318 0 0 0-1.32-1.32z"/></svg>`
};
