
import { AUTH_TOKEN } from "./constant";
import * as moment from 'moment';
import * as momentTimeZone from 'moment-timezone';

export const getToken = () => {
    const token = localStorage.getItem("access_token");
    return token || null;
};

export const setToken = (token) => {
  if (token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
};

export const removeToken = () => {
  localStorage.removeItem(AUTH_TOKEN);
};

export const validateNumberField = (event) => {
  const allowedChars = /^[0-9\.\+\-\*\/]|[Backspace|Delete|ArrowUp|ArrowDown|ArrowLeft|ArrowRight]$|^$/;
  return allowedChars.test(event.key) && event.key !== 'e' && event.key !== 'E';
}

export const formatDateToUTC = (date) => {
  const utcDate = moment(date).utc().format('YYYY-MM-DDTHH:mm:ss');
  return utcDate;
}

export const formatDateWithTimezone = (date) => {
const timezone = momentTimeZone.tz.guess();
const formattedDate = momentTimeZone(date).tz(timezone).format();
return formattedDate;
}

export const transformArray = (arr, replaceChar) => {
  return arr.map(item => {
    let labelText = item;
    if (replaceChar) {
      labelText = item.replace(new RegExp(replaceChar, 'g'), ' ');
    }
    const label = labelText.replace(/\b\w/g, char => char.toUpperCase());
    return {
      label: label,
      value: item
    };
  });
}

export const transformText = (text, replaceChar='_') => {
    const labelText = text.replace(new RegExp(replaceChar, 'g'), ' ');
    return labelText.replace(/\b\w/g, char => char.toUpperCase());
}

export const removeNonValueFilters = (filterValues) =>{
  const filters = {};
  Object.keys(filterValues).forEach((key) => {
    if (filterValues[key] !== '') {
      Object.assign(filters, { [key]: filterValues[key] });
    }
  });
  return filters;
}

export const getStatusBgColor = (status ='') => {
  let backgroundColor = "#3174ad";
  switch (status.toLowerCase()) {
      case "published":
          backgroundColor = "#28a745"; // Green
          break;
      case "scheduled":
          backgroundColor = "#ffc107"; // Yellow
          break;
      case "error":
          backgroundColor = "#dc3545"; // Red
          break;
      case "draft":
          backgroundColor = "#17a2b8"; // Blue
          break;
      default:
          backgroundColor = "#6c757d"; // Grey for any other status
          break;
  }
  return backgroundColor;
}

export const formatDate = (dateString) => new Date(dateString).toISOString().split("T")[0];

export const chartDefaultDateRange = () => {
  const currentYear = new Date().getFullYear();
  const startOfYear = new Date(`${currentYear}-01-01T00:00:00`).getTime();
  const endOfYear = new Date(`${currentYear}-12-31T23:59:59`).getTime();
  return { currentYear, startOfYear, endOfYear };
}
export const chartDefaultMonth = () => {
  const now = new Date();
  const startOfCurrentMonth = new Date(now.getFullYear(), now.getMonth(), 1).getTime();
  const endOfCurrentMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59).getTime();
  return { startOfCurrentMonth, endOfCurrentMonth }
}

export const parseNumber = (str) => parseInt(str, 10);

export const getLocalDateTime = () => {
  const now = new Date();
  const offset = now.getTimezoneOffset() * 60000; // offset in milliseconds
  const localDate = new Date(now.getTime() - offset);
  return localDate.toISOString().slice(0, 16);
};

export const nowLocal = getLocalDateTime();

export const validateImageFile = async (file, postType, socialPlatform) => {
  const errors = [];
  const warnings = [];

  if (!['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)) {
    errors.push('File must be a JPEG, JPG, or PNG image.');
  }

  if (file.size > 8 * 1024 * 1024) {
    errors.push('File size exceeds 8 MB.');
  }

  try {
    const img = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    });

    const width = img.naturalWidth;
    const height = img.naturalHeight;
    const aspectRatio = width / height;

    if (socialPlatform === 'instagram_page') {
      if (postType === 'story-image') {
        const allowedRatios = [9/16, 1, 4/5];
        const ratioMatch = allowedRatios.some(ratio => 
          Math.abs(aspectRatio - ratio) < 0.01
        );

        if (!ratioMatch) {
          warnings.push('Recommended aspect ratios: 9:16 (story), 1:1 (square), or 4:5 (portrait)');
        }
        
        if (Math.abs(aspectRatio - 1) < 0.01) {
          warnings.push('1:1 images will be cropped to 9:16 in Stories');
        }
      } else {
        const minRatio = 0.8;  // 4:5 (portrait)
        const maxRatio = 1.91; // 1.91:1 (landscape)
        const isSquare = Math.abs(aspectRatio - 1) < 0.01;

        if (!isSquare && (aspectRatio < minRatio || aspectRatio > maxRatio)) {
          errors.push(`Instagram aspect ratio must be between 4:5 and 1.91:1 (current: ${aspectRatio.toFixed(2)})`);
        }
      }
    }

    if (socialPlatform === 'facebook_page') {
      const allowedRatios = [1.91, 1, 9/16];
      const ratioMatch = allowedRatios.some(ratio => Math.abs(aspectRatio - ratio) < 0.01);
      
      if (!ratioMatch) {
        errors.push(`Facebook requires 1.91:1, 1:1, or 9:16 aspect ratio (current: ${aspectRatio.toFixed(2)})`);
      }
    }

    if (width < 320) {
      warnings.push('Width is less than 320px; image will be scaled up.');
    } else if (width > 1440) {
      warnings.push('Width exceeds 1440px; image will be scaled down.');
    }

  } catch (error) {
    errors.push('Failed to process image.');
  }

  return { errors, warnings };
};

export const validateVideoFile = (file, type, socialPlatform) => {
  return new Promise((resolve) => {
    const video = document.createElement('video');
    video.preload = 'metadata';

    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      const duration = video.duration;
      const width = video.videoWidth;
      const height = video.videoHeight;
      const aspectRatio = width / height;
      const errors = [];

      const validTypes = ['video/mp4', 'video/quicktime'];
      if (!validTypes.includes(file.type)) {
        errors.push('Invalid file type. Only MP4/MOV allowed');
      }

      if (socialPlatform === 'instagram_page') {
        if (type === 'reels') {
          if (duration > 90) errors.push("Instagram Reels cannot exceed 90 seconds.");
          if (aspectRatio < 0.5625 || aspectRatio > 0.8889) { // 9:16 to 16:9
            errors.push("Instagram Reels require 9:16 to 16:9 aspect ratio.");
          }
        }
        if (type === 'story-video') {
          if (duration > 15) errors.push("Instagram Stories cannot exceed 15 seconds.");
          if (Math.abs(aspectRatio - 9 / 16) > 0.01) {
            errors.push("Instagram Stories require 9:16 aspect ratio.");
          }
        }
      }

      if (socialPlatform === 'facebook_page') {
        if (type === 'reels') {
          if (duration > 90) errors.push("Facebook Reels cannot exceed 90 seconds.");
          if (aspectRatio < 0.5625 || aspectRatio > 0.8889) {
            errors.push("Facebook Reels require 9:16 to 16:9 aspect ratio.");
          }
        }

        if (type === 'story-video') {
          const allowedRatios = [9 / 16, 1]; // 9:16 and 1:1
          const ratioMatch = allowedRatios.some(ratio =>
            Math.abs(aspectRatio - ratio) < 0.01
          );

          if (!ratioMatch) {
            errors.push(`Facebook Story videos require 9:16 or 1:1 aspect ratio (current: ${aspectRatio.toFixed(2)})`);
          }

          if (duration > 60) {
            errors.push('Facebook Stories cannot exceed 60 seconds');
          }
        }

        if (type === 'single-video-post') {

          if (duration > 14400) { // 4 hours max
            errors.push('Facebook videos must be shorter than 4 hours');
          }
  
          if (file.size > 4 * 1024 * 1024 * 1024) { // 4GB max
            errors.push('File size exceeds Facebook 4GB limit');
          }
        }

      }

      if (type === 'reels') {
        if (file.size > 314572800) { // 300MB
          errors.push('File size exceeds 300MB limit');
        }
      }

      if (type === 'story-video') {
        if (file.size > 104857600) { // 100MB
          errors.push('File size exceeds 100MB limit');
        }
      }

      resolve(errors);
    };

    video.onerror = () => {
      resolve(['Invalid video file']);
    };

    video.src = URL.createObjectURL(file);
  });
};

export const loadImageFromUrl = (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = url;
    img.onload = () => resolve(img);
    img.onerror = (e) => reject(e);
  });
};