import PropTypes from 'prop-types';
import { memo, useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { TextField, MenuItem, Grid, ListItemIcon } from '@mui/material';
import Chart from '../../components/Chart/Chart';
import useChart from '../../hooks/useChart';
import Loader from '../../components/Loader';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

const channelIcons = {
  facebook_page: <FacebookIcon color="primary" />,
  instagram_page: <InstagramIcon color="secondary" />,
};

const ChartCard = memo(({ 
  loading, 
  title, 
  subheader, 
  chart = {}, 
  selectable = false, 
  selectedValue, 
  setSelectedValue, 
  optionsList = [], 
  formatTooltip,
  chartHeight = 375,
  renderIcon,
  ...other 
}) => {

  const { labels = [], colors = [], series = [], options = {} } = chart;

  const chartOptions = useChart({
    colors,
    plotOptions: {
      bar: {
        columnWidth: '16%',
      },
    },
    fill: {
      type: series.map((i) => i.fill),
    },
    labels,
    xaxis: {
      type: 'datetime',
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: formatTooltip,
      },
    },
    ...options,
  });

  return (
    <Card
      {...other}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <CardHeader
        title={
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={12} md={10}>
              <div>{title}</div>
              <div style={{ fontSize: 'small', color: 'gray' }}>{subheader}</div>
            </Grid>
            {selectable && (
              <Grid item xs={12} md={2}>
                <TextField
                  select
                  label="Select Channel"
                  value={selectedValue ?? ""}
                  onChange={(e) => setSelectedValue(e.target.value)}
                  size="small"
                  fullWidth
                  SelectProps={{
                    displayEmpty: true,
                    renderValue: (selected) => {
                      if (!selected) return "Select Channel"; // Placeholder text
                      const selectedOption = optionsList.find((option) => option.id === selected);
                      return selectedOption ? (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <ListItemIcon sx={{ minWidth: 24 }}>
                            {channelIcons[selectedOption.type] || null}
                          </ListItemIcon>
                          <Typography variant="body2">{selectedOption.name}</Typography>
                        </Box>
                      ) : null;
                    },
                  }}
                >
                  {optionsList.map((option) => (
                    <MenuItem key={option.id} value={option.id} sx={{ display: 'flex', alignItems: 'center' }}>
                      <ListItemIcon sx={{ minWidth: 30, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {channelIcons[option.type] || null}
                      </ListItemIcon>
                      <Typography variant="body2">{option.name}</Typography>
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
          </Grid>
        }
      />

      <CardContent sx={{px:0}}>
          <Box sx={{ p: 3, pb: 1 }}>
            {loading ? (
              <Loader />
            ) : (series.length === 0 ||labels.length === 0)? 
            (
              <Box component="div" display="flex" justifyContent="center" sx={{ textAlign: 'center' }} width="100%" my={4}>
                <Typography variant="subtitle2">No data available</Typography>
              </Box>
            ) : (series.length > 0 && labels.length > 0 && (
              <Chart
                dir="ltr"
                type="line"
                series={series}
                options={chartOptions}
                width="100%"
                height={chartHeight}
              />
            ))}
          </Box>
      </CardContent>
    </Card>
  );
});

ChartCard.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.string,
  subheader: PropTypes.string,
  chart: PropTypes.object,
  selectable: PropTypes.bool,
  selectedValue: PropTypes.any,
  setSelectedValue: PropTypes.func,
  optionsList: PropTypes.array,
  formatTooltip: PropTypes.func,
  chartHeight:PropTypes.number,
};

export default ChartCard;