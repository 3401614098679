import { Card, CardContent, CircularProgress, Stack, Typography } from '@mui/material';

export const CountCardIcons = (props) => {
    const { loading, sx, value, title, iconSection } = props;
    return (
        <Card sx={{ ...sx, height: 148, position: 'relative', pb: -15 }}>
            <CardContent sx={{  display: 'flex', flexDirection: 'column', }}>
                <Stack
                    alignItems="flex-start"
                    direction="row"
                    justifyContent="space-between"
                    spacing={3}
                >
                    <Stack spacing={1}>
                        <Typography
                            color="text.secondary"
                            variant="overline"
                        >
                            {title}
                        </Typography>
                        {loading ? <CircularProgress size={20} />
                            : <Typography variant="h4">
                                {value}
                            </Typography>}
                    </Stack>
                </Stack>
                <Stack
                    spacing={{ xs: 1, sm: 2 }}
                    direction="row"
                    sx={{
                        display: 'flex',
                        gap: '10px',
                        justifyContent:'flex-end',
                        pb:0    
                    }}
                >
                    {Object.entries(iconSection || {}).map(([channel, { count, icon }]) => (
                        <div key={channel} style={{ textAlign: 'center' }}>
                            <span style={{ fontSize: '24px', display: 'block' }}>{icon}</span>
                            <Typography
                            color="text.secondary"
                            variant="overline"
                            sx={{fontSize: '16px'}}
                        >{count}</Typography>
                        </div>
                    ))}
                </Stack>
            </CardContent>
        </Card>
    );
};


