import React, { useState } from "react";
import { Box, Button, IconButton, FormControl, TextField, MenuItem, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import AddSharpIcon from "@mui/icons-material/AddSharp";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from '@mui/icons-material/Edit';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NextArrow from "../../components/NextArrow";
import PrevArrow from "../../components/PrevArrow";

const MediaSection = ({ disabled, sliderRef, type, mediaData, newMedia, onAddMedia, onDeleteMedia, frames, frameMedia, selectedFrame, onFrameChange, onClearFrame, onImageEdit, showEditImage = false }) => {
    const theme = useTheme();
    const [currentSlide, setCurrentSlide] = useState(0);

    const handleNext = () => {
        if (currentSlide < [...mediaData, ...newMedia].length - 1) {
            setCurrentSlide((prev) => prev + 1);
        }
    };

    const handlePrev = () => {
        if (currentSlide > 0) {
            setCurrentSlide((prev) => prev - 1);
        }
    };
    const afterSlideChange = (slide) => {
        setCurrentSlide(slide);
    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        initialSlide: 0,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: [...mediaData, ...newMedia].length > 1,
        nextArrow: <NextArrow onClick={handleNext} disabled={currentSlide === [...mediaData, ...newMedia].length - 1 ||
                [...mediaData, ...newMedia].length <= 1} sx={{ right: '-12px' }} />,
        prevArrow: <PrevArrow onClick={handlePrev} disabled={currentSlide === 0 || [...mediaData, ...newMedia].length <= 1} sx={{ left: '-12px' }} />,
        className: 'file-select-slider',
    };

    return (
        <Box
            border={1}
            borderColor={theme.palette.divider}
            p={1}
            sx={{
                borderRadius: '8px',
                position: 'relative',
                opacity: disabled ? 0.6 : 1,
                pointerEvents: disabled ? 'none' : 'auto'
            }} >
            {disabled && (
                <Box
                    position="absolute"
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                    bgcolor="rgba(255, 255, 255, 0.7)"
                    zIndex={10}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Typography variant="body2" color="textSecondary">
                        Select a channel to enable media upload
                    </Typography>
                </Box>
            )}
            <FormControl fullWidth sx={{ mb: 2 }}>
                <Typography variant="body2" color="textSecondary" m={0.4}>
                    Media
                </Typography>
                <Box border={1} borderColor={theme.palette.divider} position="relative" sx={{ borderRadius: '8px' }}>
                    {(mediaData.length === 0 && newMedia.length === 0) && (
                        <Box display="flex" justifyContent="center" alignItems="center" height={200}>
                            <IconButton component="label">
                                <AddSharpIcon />
                                <input
                                    type="file"
                                    hidden
                                    multiple
                                    onChange={(e) => {
                                        onAddMedia(e);
                                        e.target.value = '';
                                    }}
                                    key={Date.now()}
                                />
                            </IconButton>
                        </Box>
                    )}
                    {(mediaData.length > 0 || newMedia.length > 0) && (
                        <Box position="relative">
                            <Slider ref={sliderRef} {...settings} afterChange={(e) => afterSlideChange(e)}>
                                {[...newMedia, ...mediaData].map((item, index) => (
                                    <>
                                        {item.file?.type.startsWith('video/') || item.img?.endsWith('.mp4') ? (
                                            <video
                                                controls
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'contain',
                                                }}
                                            >
                                                <source src={item.img} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        ) :
                                            (<Box key={index} position="relative">
                                                {showEditImage && item.img && <Button
                                                    variant="contained"
                                                    startIcon={<EditIcon />}
                                                    size="small"
                                                    onClick={() => {
                                                        onImageEdit(item)
                                                    }}
                                                    sx={{ position: 'absolute', top: 5, right: 5, zIndex: 5 }} >
                                                    Edit
                                                </Button>}
                                                <img src={item.img} alt={`media-${index}`} style={{ width: "100%", height: "100%", objectFit: "contain", borderRadius: "8px" }} />
                                                {selectedFrame && <img alt="frame-img" src={frameMedia} style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', margin: "0 auto", objectFit: 'contain' }} />}
                                            </Box>)}
                                    </>
                                ))}
                            </Slider>
                            <Box className="hover-actions" position="absolute"
                                sx={{ borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px' }}
                                left={0} right={0} bottom={0} display="flex" justifyContent="center" alignItems="center" bgcolor="rgba(0, 0, 0, 0.5)" color="#FFF" opacity={1} transition="opacity 0.3s ease-in-out">
                                {(!(type === 'single-image-post' || type === 'single-video-post' || type === 'story-video') || (mediaData.length + newMedia.length) === 0) && (<IconButton component="label" sx={{ mr: 1, color: '#FFF', }}>
                                    <AddSharpIcon />
                                    <input
                                        type="file"
                                        hidden
                                        multiple
                                        onChange={(e) => {
                                            onAddMedia(e);
                                            e.target.value = '';
                                        }}
                                        key={Date.now()}
                                    />
                                </IconButton>)}
                                <IconButton onClick={() => {
                                    const currentItem = [...newMedia, ...mediaData][currentSlide];
                                    const isNew = newMedia.some(item => item?.tempId === currentItem?.tempId);
                                    if (isNew) {
                                        onDeleteMedia(currentItem);
                                    } else {
                                        onDeleteMedia(currentItem);
                                    }
                                }}
                                    sx={{ color: '#FFF', }}>
                                    <DeleteOutlineOutlinedIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    )}
                </Box>
            </FormControl>

            {(type === "single-image-post" || type === "multi-images") && (
                <FormControl fullWidth>
                    <TextField select label="Frames" value={selectedFrame} onChange={onFrameChange}
                        InputProps={{
                            endAdornment: selectedFrame && (
                                <IconButton onClick={onClearFrame} sx={{ mr: 2 }}>
                                    <ClearIcon />
                                </IconButton>
                            ),
                        }}>
                        {frames.map((frame) => (
                            <MenuItem key={frame.id} value={frame.id}>{frame.template}</MenuItem>
                        ))}
                    </TextField>
                </FormControl>
            )}
        </Box>
    );
};

export default MediaSection;
