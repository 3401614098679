import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Typography, Box, Paper } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import makeRequest from '../../makeRequest';
import Loader from '../../components/Loader';
import ProductForm from './ProductForm';
import MUIModal from '../../components/MUIModal';
import MUILoader from '../../components/MUILoader';
import { subject } from "@casl/ability";
import { useNavigate } from 'react-router-dom';
import { Can } from '../../context/Can';
import { useSelector,useDispatch } from 'react-redux';
import { parseNumber } from '../../helpers';
import { setGlobalMessage } from '../../Redux/slices/uiSlice';
import { CHANNEL_ICONS_MAP } from '../../constant';

const ProductList = ({ currentProductId }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState(null);
  const [error, setError] = useState(null);
  const [showUserForm, setShowUserForm] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const dispatch = useDispatch();

  const authState =  useSelector(state=> state.auth);
  const userProducts = authState.user.userRoles?.map(item=>item.product) || [];
  const filteredProducts =  products.filter(fItem => userProducts.find(pItem=> fItem.id == pItem.id))


  const fetchData = () => {
    setLoading(true);
    makeRequest({
      url: `/api/products?populate=*&publicationState=preview&sort[0]=id:asc`,
      method: 'GET',
    })
      .then(respData => {
        if (respData?.data) {
          setProducts(respData.data);
        }
      })
      .catch(error => {
        console.error('Error fetching products:', error);
      }).finally(() => {
        setLoading(false);
      });
  }

  const handleDelete = async (id) => {
    if (!id) return;

    setLoading(true);
    await makeRequest({
      method: 'DELETE',
      url: `/api/products/${id}`,
    }).then(respData => {
      if (respData?.data) {
        fetchData();
        handleDeleteModalClose();
        dispatch(setGlobalMessage({ message: "Product deleted!", severity: "success" }));
      }
    }).catch(err => {
      console.error(`Product delete Error`, err);
      dispatch(setGlobalMessage({ message: "Failed to delete product. Please try again.", severity: "error" }));
    }).finally(() => {
      setLoading(false);
    });
  };

  const handleConfirmDelete = async (productData) => {
    setProduct(productData);
    setViewModal(true);
  }


  const handleDeleteModalClose = () => {
    setViewModal(false);
    if (product) {
      setProduct(null)
    }
  }

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <Box sx={{ flexGrow: 1, }}>
      <Box display="flex" sx={{ marginTop: 4, justifyContent: 'flex-end' }}>
        <Can 
          do='manage' 
          on='ManageProducts'
        >
          <Button variant="contained" color="primary" onClick={() => {
            navigate(`/settings/product/create`)
          }}>
            Create Product
          </Button>
        </Can>
      </Box>

        <TableContainer component={Paper} sx={{ marginTop: 4 }}>
      {filteredProducts.length > 0 ?
        (<Table>
          <TableHead>
            <TableRow>
              <TableCell align='left'><Typography variant="body1" fontWeight="bold">Product</Typography></TableCell>
              <TableCell align='left'><Typography variant="body1" fontWeight="bold">Channels</Typography></TableCell>
              <Can
                I="manage"
                this={subject("ManageProducts", { productId: parseNumber(currentProductId) })}
                field="productId"
              >
                <TableCell align='right'><Typography variant="body1" fontWeight="bold">Actions</Typography></TableCell>
              </Can>
            </TableRow>
          </TableHead>

          <TableBody>
            {filteredProducts.map((product) => (
              <TableRow key={product.id}>
                <TableCell align='left'>{product?.attributes.name}</TableCell>
                <TableCell align='center'>
                  {product?.attributes.channels?.data.length > 0 ? (product?.attributes.channels?.data.map((channel) => (
                    <div key={channel.id} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '10px' }}>
                      {(channel?.attributes?.channel_image_url) ? (
                        <img width={30} height={30} style={{ marginRight: '5px', borderRadius: '4px' }} src={channel?.attributes?.channel_image_url} />
                      ) : (
                        <div className='fr-text-profile'>{channel.attributes.display_name[0]}</div>
                      )}
                      <div style={{ marginTop: '6px', marginRight: '5px' }} dangerouslySetInnerHTML={{__html: CHANNEL_ICONS_MAP[channel.attributes.channel_type]}} />{channel.attributes.display_name}
                    </div>
                  ))) : '-'}
                </TableCell>
                <Can
                  I="manage"
                  this={subject("ManageProducts", { productId: parseNumber(product?.id) })}
                  field="productId"
                >
                  <TableCell align='right'>
                    <Tooltip title="Edit Product">
                      <IconButton variant="contained" onClick={() => {
                        navigate(`/settings/product/${product.id}/edit`);
                      }}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Product">
                      <IconButton variant="contained" onClick={() => handleConfirmDelete(product)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </Can>
              </TableRow>
            ))}
          </TableBody>
        </Table>): <Typography variant="body1" fontWeight="bold" sx={{ py: 4, textAlign: 'center' }}>Products not available. Create product to proceed further.</Typography>}
      </TableContainer> 

      {isLoading && <MUILoader open />}

      <MUIModal
        open={viewModal}
        handleClose={() => {
          handleDeleteModalClose()
        }}
        title="Do you want to proceed?"
      >
        <Box pt={1}>
          <Typography variant='title'>
            {`You are about to delete the product ${product?.attributes?.name}. This action cannot be undone.`}
          </Typography>

          <Box display="flex" alignItems="center" justifyContent="flex-end" gap={1} flexWrap="wrap" py={1}>
            <Button
              size="small"
              variant="outlined"
              type="submit"
              onClick={handleDeleteModalClose}
              sx={{ mt: 1 }}
            >
              Cancel
            </Button>
            <Button
              size="small"
              color='error'
              variant="contained"
              type="submit"
              onClick={() => { handleDelete(product?.id) }}
              sx={{ mt: 1 }}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </MUIModal>

      {error && (
        <Typography color="error" variant="body2" sx={{ marginTop: 2 }}>
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default ProductList;
