import React, { useContext, useEffect, useState } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { Layout } from "./theme/Layout";
import SignIn from "./pages/SignIn/SignIn";
import SignUp from "./pages/SignUp/SignUp";
import NotFound from "./pages/NotFound";
import PublicRoute from "./routes/PublicRoute";
import { useScrollToTop } from "./hooks/useScrollToTop";
import HandleInvite from "./pages/HandleInvite";
import makeRequest from "./makeRequest";
import { AbilityContext, updateAbility } from "./context/Can";
import { logoutUser, setUser } from "./Redux/slices/authSlice";
import Loader from "./components/Loader";
import Unauthorized from "./pages/Unauthorized";
import { clearMessages, setLoading, setSuccessMessage } from "./Redux/slices/uiSlice";import MUILoader from "./components/MUILoader";
import NotConfigured from "./pages/NotConfigured";
import AppRoutes from "./routes/Routes";
import { parseNumber } from "./helpers";
import SnackbarMessage from "./components/SnackbarMessage";
import ForgetPassword from "./pages/SignIn/ForgotPassword";
import ResetPassword from "./pages/SignIn/ResetPassword";

const getCurrentUser = async (token) => {
  const response = await makeRequest({
    method: "GET",
    url: `/api/users/me`,
    headers: { Authorization: `Bearer ${token}` },
  });
  return response;
};

export default function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const ability = useContext(AbilityContext);
  const authState = useSelector((state) => state.auth);
  const loading = useSelector((state) => state.ui.loading);
  const { token, user, isAuthenticated, productId } = authState;
  const uiState = useSelector((state) => state.ui);
  const { globalMessage,severity } = uiState;
  useScrollToTop();

  // const [hasAccess, setHasAccess] = useState(false);

  useEffect(() => {
    if (!token) {
      dispatch(logoutUser());
      return;
    }
    dispatch(setLoading(true));
    getCurrentUser(token)
      .then((userData) => {
        dispatch(setUser(userData));

        const allowedRoles = userData.userRoles || [];
        console.log("allowedRoles: ", allowedRoles);

        if (allowedRoles.length === 0) {
          navigate(`/onboarding`, { replace: true });
          return;
        }
        // const hasProductAccess = allowedRoles.some((role) => {
        //   return (
        //     role.role &&
        //     role.product &&
        //     role.product.id &&
        //     role.product.id === parseNumber(productId)
        //   );
        // });

        // setHasAccess(hasProductAccess);
      })
      .catch((err) => {
        console.error(`Failed to fetch user details`, err);
        dispatch(logoutUser());
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  }, [token, productId, dispatch]);

  useEffect(() => {
    if (!user || !user.userRoles) {
      return;
    }
    updateAbility(user.userRoles, ability);
  }, [user, ability, authState.productId]);

  const handleCloseSnackbar = () => {
    dispatch(clearMessages());
  };

  const publicRoutes = ["/login", "/signup", "/forgot-password", "/reset-password", "/invitation"];

  if (!isAuthenticated && !publicRoutes.includes(location.pathname)) {
    return <Navigate to="/login" replace />;
  }

  if (loading) {
    return <MUILoader open={loading} />;
  }
  if (uiState.loading) {
    return <MUILoader open={uiState.loading} />;
  }

  if (!uiState.loading && user) {
    const userRolesEmpty = user.userRoles?.length === 0;
    // if (userRolesEmpty) {
    //   const currentPath = window.location.pathname;
    //   if (currentPath === "/dashboard" || currentPath === "/schedule") {
    //     return <NotConfigured />;
    //   }
    // }

    // if (!hasAccess) {
    //   return <Unauthorized />;
    // }
    const currentPath = window.location.pathname;
    // const handleCloseSnackbar= ()=>{
    //   dispatch(clearMessages);
    // }

    return (
      <Layout isAuthenticated={user}>
        {userRolesEmpty && (currentPath === "/" || currentPath === "/dashboard" || currentPath === "/schedule") ? <NotConfigured /> : <AppRoutes />}
        {/* !hasAccess ? <Unauthorized /> : <AppRoutes />  */}
        {globalMessage && <SnackbarMessage
          open={!!globalMessage}
          message={globalMessage}
          severity={severity}
          //  autoHideDuration={3000}
          onClose={handleCloseSnackbar}
        />}

      </Layout>
    );
  }

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={<PublicRoute restricted={true} element={<SignIn />} />}
        />
        <Route
          path="/signup"
          element={<PublicRoute restricted={true} element={<SignUp />} />}
        />
        <Route
          path="/login"
          element={<PublicRoute restricted={true} element={<SignIn />} />}
        />
        <Route
          path="/invitation"
          element={<PublicRoute restricted={true} element={<HandleInvite />} />}
        />
        <Route path="*" element={<NotFound />} />
        <Route path="/forgot-password" element={<ForgetPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
      </Routes>
    </>
  );
}
