import React, { useState, useEffect } from "react";
import {
  Alert,
  Button,
  Card,
  Form,
  Input,
  message,
  Typography,
  Spin,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import { Box, Unstable_Grid2 as Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../Redux/slices/authSlice";
import { setErrorMessage, setLoading, setGlobalMessage, } from "../../Redux/slices/uiSlice";
import makeRequest from "../../makeRequest";

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [formError, setFormError] = useState("");
  const [password, setPassword] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [resetLoading, setResetLoading] = useState(false);

  const onFinish = async (values) => {
    setIsLoading(true);
    setEmailError("");
    setFormError("");
    setPasswordError("");

    if (!values.email) {
      message.error("Please enter your email.");
      setIsLoading(false);
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(values.email)) {
      message.error("Please enter a valid email address.");
      setIsLoading(false);
      return;
    }
    if (!values.password) {
      message.error("Please enter your password.");
      setIsLoading(false);
      return;
    }

    const requestData = {
      identifier: values.email,
      password: values.password,
    };

    dispatch(setLoading(true));

    try {
      const response = await makeRequest(
        {
          url: `/api/auth/local`,
          method: "POST",
          data: requestData,
        },
        false
      );

      if (response?.error) {
        throw response.error;
      }

      console.log('response.user?.userRoles: ', response.user?.userRoles);
      const rolePriority = { Owner: 1, Manager: 2, Analyst: 3 };
      const sortedRoles = response.user?.userRoles?.sort(
        (a, b) => rolePriority[a.role] - rolePriority[b.role]
      );
      const productId = sortedRoles?.[0]?.product?.id || null;
      dispatch(
        loginSuccess({
          token: response?.jwt,
          user: response?.user,
          productId: productId,
        })
      );

      dispatch(
        setGlobalMessage({
          message: `Welcome, ${response?.user?.username}!`,
          severity: "success",
        })
      );

      if(response.user?.userRoles?.length === 0) {
        navigate(`/onboarding`, { replace: true });
        return;
      }

      navigate(`/dashboard`, { replace: true });
    } catch (error) {
      console.error("Login Error:", error);

      if (error?.message?.toLowerCase().includes("email")) {
        setEmailError("Incorrect email. Please check your email.");
        message.error("Incorrect email. Please try again.");
      } else if (error?.message?.toLowerCase().includes("password")) {
        setPasswordError("Incorrect password. Please try again.");
        message.error("Incorrect password! Please try again.");
      } else {
        setFormError("Something went wrong! Please try again.");
        message.error("Invalid Email or Password");
        // dispatch(setGlobalMessage({ message: "Invalid Email or Password", severity: "error" }));
      }
    } finally {
      setIsLoading(false);
      dispatch(setLoading(false));
    }
  };
  const handleForgotPassword = async () => {
    if (!resetEmail) {
      message.error("Please enter your email.");
      return;
    }

    setResetLoading(true);

    try {
      await makeRequest(
        {
          url: `/api/auth/forgot-password`,
          method: "POST",
          data: { email: resetEmail },
        },
        false
      );

      message.success("Password reset email sent! Check your inbox.");
      setIsModalOpen(false);
      setResetEmail("");
    } catch (error) {
      console.error("Forgot Password Error:", error);
      message.error("Failed to send reset email. Please try again.");
    } finally {
      setResetLoading(false);
    }
  };
  return (
    <Box component="main" sx={{ display: "flex", flex: "1 1 auto" }}>
      <Grid container sx={{ flex: "1 1 auto" }}>
        <Grid
          xs={12}
          sx={{
            backgroundColor: "background.paper",
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <Box
            sx={{
              backgroundColor: "background.paper",
              flex: "1 1 auto",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box sx={{ maxWidth: 500, px: 3, py: "100px", width: "100%" }}>
              <Typography component="h2" variant="h5" style={{ textAlign: "center" }}>
                Sign In
              </Typography>

              <Card style={{ width: "100%" }}>
                {formError && (
                  <Alert
                    className="alert_error"
                    message={formError}
                    type="error"
                    closable
                    afterClose={() => setFormError("")}
                  />
                )}
                <Form name="sign-in-form" layout="vertical" onFinish={onFinish} autoComplete="off" validateTrigger={["onSubmit", 'onChange']}>
                  {/* <Form.Item
                    label="Email"
                    name="email"
                    validateStatus={emailError ? "error" : ""}
                    help={emailError}
                    rules={[
                      {
                        required: true,
                        type: "email",
                        message: "Please enter a valid email address",
                      },
                    ]}
                  >
                    <Input placeholder="Email address" />
                  </Form.Item> */}
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      { required: true, message: "Please enter your email." },
                      { type: "email", message: "Please enter a valid email address." },
                    ]}
                  >
                    <Input placeholder="Email address" />
                  </Form.Item>
                  <Form.Item
                    label="Password"
                    name="password"
                    // validateStatus={passwordError ? "error" : ""}
                    // help={passwordError}
                    rules={[
                      { required: true, message: "Please enter your password" }
                    ]}
                  >
                    <Input.Password
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login_submit_btn"
                      sx={{ textTransform: "none" }}
                      disabled={isLoading}
                    >
                      Sign In {isLoading && <Spin size="small" />}
                    </Button>
                  </Form.Item>
                </Form>
                <Typography.Paragraph className="form_help_text">
                  <Link to="/forgot-password">Forgot Password?</Link>
                </Typography.Paragraph>
                <Typography.Paragraph className="form_help_text">
                  <Link to="/signup">Sign Up</Link>
                </Typography.Paragraph>
              </Card>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SignIn;
