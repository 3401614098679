import { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import ChartCard from './ChartCard';
import makeRequest from '../../../src/makeRequest';
import { useDataContext } from '../../context/DataContext';
import { chartDefaultDateRange } from '../../helpers';


export default function LikesCommentChart({ productId }) {
  const initialChartData = { labels: [], series: [] };
  const { channels } = useDataContext();
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState(initialChartData);
  const [selectedChannel, setSelectedChannel] = useState(null);

  const filteredChannels = useMemo(
    () => channels?.filter((chan) => chan.products.find(i => i.id == Number(productId))) || [],
    [channels, productId]
  );

/*
  async function fetchLikesAndCommentsOverTime() {
    try {
      setLoading(true);
      const chartDataResp = await makeRequest({
        url: `/api/post-stats/likes-comments-chart-data`,
        method: 'GET',
      });
      console.log('chartDataResp: ', chartDataResp);
      
      if (chartDataResp?.series.some(series => series.data.length !== chartDataResp?.labels.length)) {
        console.error('Error: Mismatch in data lengths for series and labels');
        setChartData(initialChartData);
        return;
      }

      setChartData({
        labels: chartDataResp.labels.map(date => moment(date).format('MM/DD/YYYY')),
        series: chartDataResp.series,
      });
    } catch (error) {
      console.error('Error fetching product stats:', error);
      setChartData(initialChartData);
    } finally {
      setLoading(false);
    }
  }
*/

  async function fetchChannelLikesAndComments(cId) {
    try {
      setLoading(true);
      const chartDataResp = await makeRequest({
        url: `/api/channels/channel-post-metrics?productId=${productId}&channelId=${cId}`,
        method: 'GET',
      });
      const cData = chartDataResp?.data?.chartData || [];
      
      if (cData.series.some(series => series.data.length !== cData.labels.length)) {
        console.error('Error: Mismatch in data lengths for series and labels');
        setChartData(initialChartData);
        return;
      }
    setChartData({
        labels: cData.labels.map(date => moment(date).format('MM/DD/YYYY')),
        series: cData.series
    });

    //   setChartData({
    //     labels:chartDataResp.labels,
    //     series:[
    //     ...chartDataResp.likes,
    //     ...chartDataResp.comments,
    //   ]});
    } catch (error) {
      console.error('Error fetching product stats:', error);
      setChartData(initialChartData);
    } finally {
      setLoading(false);
    }
  }
  
    useEffect(() => {
        if (!selectedChannel) return;
        fetchChannelLikesAndComments(selectedChannel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedChannel]);

    useEffect(() => {
        // if (filteredChannels.length === 0) {
        //     setSelectedChannel(null);
        //     setChartData(initialChartData);
        // }
        
        if (filteredChannels.length > 0) {
            setSelectedChannel(filteredChannels[0]?.id);
        }
    }, [filteredChannels]);


  const memoizedChartOptions = useMemo(() => ({
    chart: {
      stacked: false,
      type: 'line',
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true
      },
      toolbar: {
        autoSelected: 'zoom',
        show: true,
        offsetX: 0,
        offsetY: -38,
        // selection: true,
        zoom: true,
        pan: true,
      }
    },
    xaxis: {
      type: 'datetime',
      min: chartDefaultDateRange().startOfYear,
      max: chartDefaultDateRange().endOfYear,
    },
    dataLabels: {
      enabled: false
    },
    // tooltip: {
    //     shared: true,
    //     intersect: false
    //   },
    //   legend: {
    //     // floating: true,
    //     position: 'top',
    //     horizontalAlign: 'right',
    //     // offsetX: 0,
    //     // offsetY: 13
    //   },

  }), []);
  return (
    <ChartCard
      loading={loading}
      title="Likes & Comments stats"
    //   subheader="All published post likes & comment"
      chart={{
        ...chartData,
        options: memoizedChartOptions
      }}
      selectable
      selectedValue={selectedChannel}
      setSelectedValue={setSelectedChannel}
      optionsList={filteredChannels.map((c) => ({ id: c.id, name: c.display_name , type:c.channel_type}))}
      formatTooltip={(value) => (value ? `${value.toFixed(0)}` : value)}
      //onScroll={fetchNextData}
      chartHeight={385}
    />
  );
}
