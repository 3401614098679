import React, { useState, useEffect } from 'react';
import {
    TextField,
    Button,
    Stack,
    Paper,
    Box,
    Typography,
    IconButton,
    Tooltip,
    useTheme,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import DeleteIcon from '@mui/icons-material/Delete';

const ProductDetailsForm = ({ onSubmit, initialValues, isReviewMode, onEdit, onProceed }) => {
    const theme = useTheme();
    const [name, setName] = useState(initialValues.name || '');
    const [details, setDetails] = useState(initialValues.details || '');
    const [url, setUrl] = useState(initialValues.url || '');
    const [logoFile, setLogoFile] = useState(null); 
    const [logo, setLogo] = useState(initialValues.logo ? initialValues.logo[0]?.url || initialValues.logo[0]?.attributes?.url || null : null);
    const [logoId, setLogoId] = useState(initialValues.logo ? initialValues.logo[0]?.id || null : null);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setName(initialValues.name || '');
        setDetails(initialValues.details || '');
        setUrl(initialValues.url || '');
        const logoArray = initialValues.logo || [];
        setLogo(logoArray[0]?.url || logoArray[0]?.attributes?.url || null);
        setLogoId(logoArray[0]?.id || null);
    }, [initialValues]);

    const validate = () => {
        const newErrors = {};
        const urlRegex = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(\/\S*)?$/;

        if (!name.trim()) newErrors.name = 'Name is required';
        if (!details.trim()) newErrors.details = 'Details are required';
        if (!url.trim()) {
            newErrors.url = 'URL is required';
        } else if (!urlRegex.test(url.trim())) {
            newErrors.url = 'Invalid URL format';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validate()) return;
      
        const productData = {
          name,
          details,
          url,
          logo: logoId ? [{ id: logoId }] : [],
          channels: initialValues.channels || [],
          productScreens: initialValues.productScreens || [],
          description: initialValues.description || '',
          logoFile
        };
      
        await onSubmit(productData);
      };

    const handleLogoChange = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = (e) => setLogo(e.target.result);
        reader.readAsDataURL(file);
        setLogoFile(file);
    };

    const handleDeleteLogo = async () => {
        setLogo(null);
        setLogoId(null);
        setLogoFile(null); 
    };

    return (
        <Paper sx={{ p: 2 }}>
            {isReviewMode ? (
                <Box>
                    <Typography variant="h6">Product Details</Typography>
                    <Typography>Name: {name}</Typography>
                    <Typography>Details: {details}</Typography>
                    <Typography>URL: {url}</Typography>
                    {logo && (
                        <Box sx={{ mt: 2, position: 'relative', display: 'inline-block' }}>
                            <img
                                src={logo}
                                alt="Logo"
                                style={{ maxWidth: '100px', maxHeight: '100px', borderRadius: '8px' }}
                            />
                        </Box>
                    )}
                    <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
                        <Button variant="outlined" onClick={onEdit} startIcon={<EditIcon />}>
                            Edit
                        </Button>
                        <Button variant="contained" onClick={onProceed}>
                            Proceed
                        </Button>
                    </Box>
                </Box>
            ) : (
                <Stack spacing={2}>
                    <TextField
                        label="Product Name"
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value)
                            setErrors((prev) => ({ ...prev, name: undefined }));
                        }}
                        error={!!errors.name}
                        helperText={errors.name}
                        fullWidth
                        required
                    />
                    <TextField
                        label="Product Details"
                        value={details}
                        onChange={(e) => {
                            setDetails(e.target.value)
                            setErrors((prev) => ({ ...prev, details: undefined }));
                        }}
                        multiline
                        rows={4}
                        error={!!errors.details}
                        helperText={errors.details}
                        fullWidth
                        required
                    />
                    <Box
                        sx={{
                            border: '1px dashed #ccc',
                            borderRadius: '8px',
                            p: 2,
                            textAlign: 'center',
                            position: 'relative',
                        }}
                    >
                        <Typography variant="body2" color="textSecondary" mb={1}>
                            Logo
                        </Typography>
                        {!logo ? (
                            <label htmlFor="logo-upload">
                                <IconButton component="span">
                                    <AddSharpIcon />
                                </IconButton>
                                <input
                                    type="file"
                                    id="logo-upload"
                                    hidden
                                    accept="image/*"
                                    onChange={handleLogoChange}
                                />
                            </label>
                        ) : (
                            <Box sx={{ position: 'relative', display: 'inline-block' }}>
                                <img
                                    src={logo}
                                    alt="Logo"
                                    style={{ maxWidth: '100px', maxHeight: '100px', borderRadius: '8px' }}
                                />
                                <Tooltip title="Delete Logo">
                                    <IconButton
                                        onClick={handleDeleteLogo}
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            right: 0,
                                            backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                            color: 'white',
                                            '&:hover': { backgroundColor: 'red' },
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        )}
                    </Box>
                    <TextField
                        label="Website URL"
                        placeholder='https://example.com'
                        value={url}
                        onChange={(e) => {
                            setUrl(e.target.value)
                            setErrors((prev) => ({ ...prev, url: undefined }));
                        }}
                        error={!!errors.url}
                        helperText={errors.url}
                        fullWidth
                        required
                        inputProps={{ 
                            // pattern: "^(https?:\\/\\/)?([\\w.-]+)\\.([A-Za-z]{2,})(\\/\\S*)?$",
                            // title: "Please enter a valid URL (e.g., http://example.com or example.com)",
                            sx: {
                                "&::placeholder": {
                                  color: theme.palette.text.secondary, 
                                  opacity: 1, 
                                },
                              }, 
                        }}
                    />

                    <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <Button
                            variant="contained"
                            onClick={handleSubmit}
                            disabled={isLoading}
                        >
                            {isLoading ? 'Updating...' : 'Update Product'}
                        </Button>
                    </Box>
                </Stack>
            )}
        </Paper>
    );
};

export default ProductDetailsForm;