import React from "react";
import { Routes, Route, useParams, Navigate } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import NotFound from "../pages/NotFound";
import PostStatsChart from "../components/PostStatsChart";
import ViewStatsTable from "../pages/ViewStats";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SettingsIcon from "@mui/icons-material/Settings";
import NotificationsIcon from "@mui/icons-material/Notifications";
import StackedLineChartOutlinedIcon from "@mui/icons-material/StackedLineChartOutlined";
import Dashboard from "../pages/Dashboard";
import Schedule from "../pages/Schedule";
import EditPost from "../pages/Schedule/EditPost";
import CreatePost from "../pages/Schedule/CreatePost";
import Notifications from "../pages/Notifications";
import Settings from "../pages/Settings";
import Product from "../pages/Settings/Product";
import { useSelector } from "react-redux";
// import ManualPostCreation from "../pages/Schedule/ManualPostCreation";
import { RouteCan } from "../context/RouteCan";
import { subject } from "@casl/ability";
import { parseNumber } from "../helpers";
import Onboarding from "../pages/Onboading";

export const commonRouteItems = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <DashboardIcon />,
  },
  {
    title: "Schedule",
    path: "/schedule",
    icon: <CalendarMonthIcon />,
  },
  // {
  //   title: "Notifications",
  //   path: "/notifications",
  //   icon: <NotificationsIcon />,
  // },
  {
    title: "Settings",
    path: "/settings",
    icon: <SettingsIcon />,
  },
];

const AppRoutes = () => {
  const authState = useSelector((state) => state.auth);
  const productId = parseNumber(authState.productId);

  return (
    <Routes>
      <Route element={<PrivateRoute />}>
        <Route
          path="/onboarding"
          element={<Onboarding />}
        />
        {/* Schedule Routes */}
        <Route
          path="/schedule"
          element={
            <RouteCan
              I="view"
              this={subject("SchedulePost", { productId })}
              field="productId"
            >
              <Schedule productId={productId} />
            </RouteCan>
          }
        />
        <Route
          path="/schedule?view=calendar"
          element={
            <RouteCan
              I="view"
              this={subject("SchedulePost", { productId })}
              field="productId"
            >
              <Schedule productId={productId} />
            </RouteCan>
          }
        />
        <Route
          path="/schedule?view=posts"
          element={
            <RouteCan
              I="view"
              this={subject("SchedulePost", { productId })}
              field="productId"
            >
              <Schedule productId={productId} />
            </RouteCan>
          }
        />
        <Route
          path="/schedule/edit-post/:id"
          element={
            <RouteCan
              I="manage"
              this={subject("ManagePosts", { productId })}
              field="productId"
            >
              <EditPost currentProductId={productId} pageType='edit-page' />
            </RouteCan>
          }
        />
        <Route
          path="/schedule/create-post"
          element={
            <RouteCan
              I="manage"
              this={subject("ManagePosts", { productId })}
              field="productId"
            >
              <CreatePost currentProductId={productId} />
            </RouteCan>
          }
        />
        {/* Dashboard Route */}

        <Route path="/" element={<Navigate to="/dashboard" replace />} />
        <Route
          path="/dashboard"
          element={
            <RouteCan
              I="view"
              this={subject("Dashboard", { productId })}
              field="productId"
            >
              <Dashboard productId={productId} />
            </RouteCan>
          }
        />
        {/* Settings Routes */}
        <Route
          path="/settings"
          element={
            <RouteCan
              I="view"
              this={subject("Settings", { productId })}
              field="productId"
            >
              <Settings currentProductId={productId} />
            </RouteCan>
          }
        />
        <Route
          path="/settings?view=channels"
          element={
            <RouteCan
              I="view"
              this={subject("Settings", { productId })}
              field="productId"
            >
              <Settings currentProductId={productId} />
            </RouteCan>
          }
        />
        <Route
          path="/settings?view=products"
          element={
            <RouteCan
              I="view"
              this={subject("Products", { productId })}
              field="productId"
            >
              <Settings currentProductId={productId} />
            </RouteCan>
          }
        />
        <Route
          path="/settings/product/create"
          element={
            <RouteCan
              I="manage"
              this={subject("ManageProducts", { productId })}
              field="productId"
            >
              <Product currentProductId={productId} />
            </RouteCan>
          }
        />
        <Route
          path="/settings/product/:id/edit" 
          element={
            <RouteCan
              I="manage"
              this={subject("ManageProducts", { productId })}
              field="productId"
            >
              <Product currentProductId={productId} />
            </RouteCan>
          }
        />
        <Route
          path="/settings/product/:id/edit"
          element={
            <RouteCan
              I="manage"
              this={subject("ManageUsers", { productId })}
              field="productId"
            >
              <Product currentProductId={productId} />
            </RouteCan>
          }
        />
      </Route>
      {/* <Route path="*" element={<NotFound />} /> */}
    </Routes>
  );
};

export default AppRoutes;
