import { useState, useEffect } from 'react';
import moment from 'moment';
import ChartCard from './ChartCard';
import makeRequest from '../../makeRequest';
import { chartDefaultDateRange } from '../../helpers';
import { CHANNEL_ICONS_MAP } from '../../constant';

export default function ProductChannelStats({ productId }) {
    const initialChartData = {};
    const [loading, setChannelStatsChartLoading] = useState(false);
    const [chartData, setChartData] = useState(initialChartData);

    const fetchProductChannelStats = async (pId) => {
        try {
            setChannelStatsChartLoading(true);
            if (!pId) {
                setChartData(initialChartData);
                console.error('Product ID is required to fetch channel stats.');
                return;
            }

            const productChannelStatsRes = await makeRequest({
                url: `/api/products/${pId}/channels-stats-chart-data`,
                method: 'GET',
            });

            if (!productChannelStatsRes) {
                console.error('Invalid response from API:', productChannelStatsRes);
                setChartData(initialChartData);
                return;
            }
            const formattedData = productChannelStatsRes?.data?.chartData;
            if (formattedData.series.some(series => series.data.length !== formattedData.labels.length)) {
                setChartData(initialChartData);
                return;
            }
            const seriesWithIcons = formattedData.series.map((series) => ({
                ...series,
                icon: CHANNEL_ICONS_MAP[series.channelType] || null, // Assign the correct icon
            }));
            setChartData({
                labels: formattedData.labels.map(date => moment(date).format('MM/DD/YYYY')),
                // series: formattedData.series
                series: seriesWithIcons

            });

        } catch (error) {
            console.error('Failed to fetch product channel stats:', error);
            setChartData(initialChartData);
        } finally {
            setChannelStatsChartLoading(false);
        }
    };

    useEffect(() => {
        fetchProductChannelStats(productId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productId]);

    const chartOptions = {
        chart: {
            type: 'line',
            zoom: {
                type: 'x',
                enabled: true,
                autoScaleYaxis: true
            },
            toolbar: {
                autoSelected: 'zoom',
                show: true,
                offsetX: 0,
                offsetY: -28,
            }
        },
        xaxis: {
            type: 'datetime',
            min: chartDefaultDateRange().startOfYear,
            max: chartDefaultDateRange().endOfYear,
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: true,
            formatter: function(seriesName) {
                const seriesData = chartData.series.find(item => item.name === seriesName);
                return `${seriesData?.icon || ''} ${seriesName}`;
              }
        },
    }


    return (
        <ChartCard
            loading={loading}
            title={productId ? 'Connected channels insights' : ''}
            chart={{
                ...chartData,
                options: chartOptions
            }}
            formatTooltip={(value) => (value ? `${value.toFixed(0)} Followers` : value)}
            chartHeight={385}
            renderIcon={(channelType) => CHANNEL_ICONS_MAP[channelType] || null}
        />
    );
}