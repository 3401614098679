import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

import authReducer from "./slices/authSlice";
import uiReducer from "./slices/uiSlice";
import dataReducer from "./slices/dataSlice";
import chatBotReducer from './slices/chatBotSlice';

const authPersistConfig = {
  key: "auth",
  storage,
};

const chatBotPersistConfig = {
  key: "chatbot",
  storage,
  blacklist: ['isLoading',
    // 'messages',
    // 'currentStep',
    // 'formData'
  ],
};

const persistedAuthReducer = persistReducer(authPersistConfig, authReducer);

export const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
    ui: uiReducer,
    data: dataReducer,
    chatbot: persistReducer(chatBotPersistConfig, chatBotReducer),
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
