import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setGlobalMessage } from './uiSlice';
import makeRequest from '../../makeRequest';

export const submitProduct = createAsyncThunk(
  'chatbot/submitProduct',
  async (formData, { dispatch }) => {
    try {
      const productData = {
        name: formData.name,
        details: formData.details,
        url: formData.url,
        description: formData.description,
        channels: formData.channels,
        productScreens: formData.productScreens,
        logo: formData.logo?.length ? formData.logo : [],
      };

      const postData = new FormData();
      
      if(formData?.logoFile){
        postData.append('logo', formData?.logoFile);
      }
      
      if (formData.files?.length) {
        formData.files.forEach(file => {
          postData.append('files', file);
        });
      }
      postData.append('data', JSON.stringify(productData));

      const response = await makeRequest({
        url: '/api/products',
        method: 'POST',
        data: postData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      
      return response.data;
    } catch (error) {
      dispatch(setGlobalMessage({
        message: error.message || "Failed to create product",
        severity: "error"
      }));
      throw error;
    }
  }
);

const initialState = {
  messages: [{
    type: 'bot',
    content: 'Hello there! My name is Frappe, and I\'m ready to assist you with your product onboarding process. Let\'s begin by creating a new product together. What name would you like to give it?',
  }],
  currentStep: 'name',
  formData: {
    name: '',
    details: '',
    url: '',
    channels: [],
    description: '',
    productScreens: [],
    logo: [],
    files: [],
    metadataFetched: false,
    selectedChannels: [],
    frames: []
  },
  isLoading: false
};

const chatbotSlice = createSlice({
  name: 'chatbot',
  initialState,
  reducers: {
    addMessage: (state, action) => {
      state.messages.push(action.payload);
    },
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
    updateFormData: (state, action) => {
      const newFormData = { ...state.formData, ...action.payload };
      if (action.payload.logo !== undefined) {
        newFormData.logo = Array.isArray(action.payload.logo)
          ? action.payload.logo
          : action.payload.logo
            ? [action.payload.logo]
            : [];
      }

      if (action.payload.channels !== undefined) {
        newFormData.channels = action.payload.channels?.map(ch => ({
          id: ch.id,
          attributes: ch.attributes || ch
        })) || state.formData.channels;
      }

      if (action.payload.productScreens !== undefined) {
        newFormData.productScreens = action.payload.productScreens?.map(ps => ({
          id: ps.id,
          attributes: ps.attributes || ps
        })) || state.formData.productScreens;
      }

      state.formData = newFormData;
    },
    resetFormData: (state) => {
      state.formData = initialState.formData;
    },
    resetChatbot: (state) => {
      return {
        ...initialState,
        formData: {
          ...initialState.formData,
        }
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(submitProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.formData.id = action.payload.id;
      })
      .addCase(submitProduct.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

export const { 
  addMessage, 
  setCurrentStep, 
  updateFormData,
  resetFormData,
  resetChatbot
} = chatbotSlice.actions;

export default chatbotSlice.reducer;