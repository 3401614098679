import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, DialogActions, MenuItem, useTheme, Tooltip, IconButton, InputAdornment, Typography, Dialog, DialogContent } from '@mui/material';
import MUIAutocomplete from '../../components/MUIAutoComplete';
import UploadFiles from './UploadFiles';
import MUIModal from '../../components/MUIModal';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Can } from '../../context/Can';
import { subject } from "@casl/ability";
import { parseNumber } from '../../helpers';
import { setGlobalMessage } from '../../Redux/slices/uiSlice';
import { useDispatch, useSelector } from "react-redux";
import makeRequest from '../../makeRequest';
import MUILoader from '../../components/MUILoader';
import CloseIcon from "@mui/icons-material/Close";

import Zoom from "react-medium-image-zoom";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import InfoIcon from "@mui/icons-material/Info";
import PrevArrow from '../../components/PrevArrow';
import NextArrow from '../../components/NextArrow';
import { updateFormData } from '../../Redux/slices/chatBotSlice';

const ProductForm = ({
    formType,
    currentProductId,
    product,
    onSubmit,
    showUserFields,
    initialValues,
    hideFields = [],
    onMetadataFetched,
    onCancel
}) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [name, setName] = useState(initialValues?.name || '');
    const [details, setDetails] = useState(initialValues?.details || '');
    const [url, setUrl] = useState(initialValues?.url || '');
    const [description, setDescription] = useState('');
    const [channels, setChannels] = useState([]);
    const [files, setFiles] = useState([]);
    const [removedFiles, setRemovedFiles] = useState([]);
    const [validationErrors, setValidationErrors] = useState([]);
    const [users, setUsers] = useState([]);
    const [newUser, setNewUser] = useState({ email: '', role: 'Owner' });
    const [openAddUserDialog, setOpenAddUserDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [logo, setLogo] = useState(null);
    const [hover, setHover] = useState(false);
    const [logoName, setLogoName] = useState("");
    const [logoId, setLogoId] = useState(null);
    const [productScreens, setProductScreens] = useState([]);
    const [logoFile, setLogoFile] = useState(null); 

    const validateFields = () => {
        const errors = {};

        if (!name.trim()) {
            errors.name = "Name is required.";
        }

        if (!details.trim()) {
            errors.details = "Details is required.";
        }

        if (!url.trim()) {
            errors.url = "URL is required.";
        } else {
            const urlPattern = /^(https?:\/\/)?([\w.-]+)+(:\d+)?(\/[\w.-]*)*\/?$/;
            if (!urlPattern.test(url)) {
                errors.url = "Please enter a valid URL.";
            }
        }

        // if (channels.length === 0) {
        //     errors.channels = "At least one channel must be selected.";
        // }

        if (files.length > 0) {
            const invalidFiles = files.filter(file => !file.name || !file.type);
            if (invalidFiles.length > 0) {
                errors.files = "One or more files are invalid.";
            }
        }

        // if (users.length === 0) {
        //     errors.users = "At least one user must be added.";
        // }

        return errors;
    };

    const handleOpen = (index) => {
        setCurrentIndex(index);
        setOpen(true);
    };

    const handleClose = () => setOpen(false);

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % productScreens.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + productScreens.length) % productScreens.length);
    };
    const handleSubmit = (event) => {
        event.preventDefault();

        const errors = validateFields();
        if (Object.keys(errors).length > 0) {
            setValidationErrors(errors);
            return;
        }
        const isUpdating = !!product;
        const productData = {
            name,
            details,
            description,
            url,
            channels,
            files,
            removedFiles,
            users,
            productScreens: productScreens.length > 0 ? productScreens.map((pItem) => pItem.id) : [],
            logo: logoId ? [{ id: logoId }] : [],
            logoFile
        };
        onSubmit(productData);
        if(!formType){
            dispatch(setGlobalMessage({ message: isUpdating ? "Product updated successfully!" : "Product created successfully!", severity: "success" }));
        }
    };

    // const handleAddUser = () => {
    //     setUsers([...users, { ...newUser, id: `temp-${Date.now()}` }]);
    //     setOpenAddUserDialog(false);
    // };
    const handleAddUser = () => {
        try {
            setUsers([...users, { ...newUser, id: `temp-${Date.now()}` }]);
            // dispatch(setGlobalMessage({message: "User will be invited shortly!",severity: "success"}));
            dispatch(setGlobalMessage({ message: "User added successfully!", severity: "success" }));
        } catch (error) {
            dispatch(setGlobalMessage({ message: "Failed to add user. Please try again.", severity: "error" }));
        } finally {
            setOpenAddUserDialog(false);
        }
    };
    // const handleDeleteUser = (id) => {
    //     setUsers(users.filter(user => user.id !== id));
    // };
    const handleDeleteUser = (id) => {
        try {
            setUsers(users.filter(user => user.id !== id));
            dispatch(setGlobalMessage({ message: "User deleted!", severity: "success" }));
        } catch (error) {
            dispatch(setGlobalMessage({ message: "Failed to delete user. Please try again.", severity: "error" }));
        }
    };
    const handleOpenAddUserDialog = () => {
        setOpenAddUserDialog(true);
    };

    const handleCloseAddUserDialog = () => {
        setOpenAddUserDialog(false);
    };

    const handleFetchMetaData = async () => {
        if (!url.trim()) {
            dispatch(setGlobalMessage({ message: "Please enter a valid URL.", severity: "warning" }));
            return;
        }
        setIsLoading(true);
        dispatch(setGlobalMessage({ message: "Fetching product metadata...", severity: "info" }));
        const source = initialValues || product;
        makeRequest({
            url: source ? `/api/products/${source.id}/get-product-meta-data` : `/api/products/get-product-meta-data`,
            method: 'POST',
            data: { url },
        })
            .then(respData => {
                if (respData) {
                    setDescription(respData.description || "");
                    setProductScreens(respData.productScreens || []);
                    if(formType === 'chatbot'){
                        dispatch(updateFormData({
                            description: respData.description || "",
                            productScreens: respData.productScreens || [],
                            metadataFetched: true, 
                          }));
                        }
                    onMetadataFetched?.(true);
                    dispatch(setGlobalMessage({ message: "Metadata fetched successfully!", severity: "success" }));
                } else {
                    throw new Error(respData.error || "Failed to fetch metadata");
                }
            }).catch(error => {
                console.error(`Fetching product meta data Error`, error);
                dispatch(setGlobalMessage({ message: error.message || "Failed to fetch metadata", severity: "error" }));
                onMetadataFetched?.(false);
            }).finally(() => {
                setIsLoading(false);
            });
    };

    const handleAddLogo = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = (e) => setLogo(e.target.result);
        reader.readAsDataURL(file);
        setLogoName(file.name);
        setLogoFile(file);      
    };
    const handleDelete = async () => {
        try {
            if (product) {
                await makeRequest({
                    method: "DELETE",
                    url: `/api/products/${product.id}/logo`,
                });
            }
            setLogo(null);
            setLogoName("");
            setLogoId(null);
        } catch (error) {
            console.error("Failed to delete:", error);
        }
    };

    //   // Download logo
    //   const handleDownload = () => {
    //     if (logo) {
    //       const link = document.createElement("a");
    //       link.href = logo;
    //       link.download = logoName || "logo.png";
    //       document.body.appendChild(link);
    //       link.click();
    //       document.body.removeChild(link);
    //     }
    //   };

    useEffect(() => {
        const source = initialValues || product;
        const attributes = source?.data?.attributes || source?.attributes || source || {};
        const relations = {
            channels: source?.data?.attributes?.channels?.data ||
                source?.attributes?.channels?.data ||
                source?.channels?.data ||
                source?.channels || [],
            productScreens: source?.data?.attributes?.productScreens?.data ||
                source?.attributes?.productScreens?.data ||
                source?.productScreens?.data ||
                source?.productScreens || [],
            logo: source?.data?.attributes?.logo?.data ||
                source?.attributes?.logo?.data ||
                source?.logo?.data ||
                source?.logo
        };

        setName(attributes.name || '');
        setDetails(attributes.details || '');
        setUrl(attributes.url || '');
        setDescription(attributes.description || '');

        setChannels(
            relations.channels.map((cItem) => ({
                id: cItem.id,
                label: `${cItem.attributes?.display_name || cItem.display_name} - ${cItem.attributes?.channel_type || cItem.channel_type}`,
                data: cItem.attributes ? { id: cItem.id, ...cItem.attributes } : cItem
            }))
        );

        setProductScreens(
            relations.productScreens.map(ps => ({
                id: ps.id,
                attributes: ps.attributes || ps,
                url: ps.attributes?.url || ps.url
            }))
        );
        if (relations.logo) {
            const logoData = Array.isArray(relations.logo) ? relations.logo[0] : relations.logo;
            setLogo(logoData?.attributes?.url || logoData?.url);
            setLogoId(logoData?.id);
        } else {
            setLogo(null);
            setLogoId(null);
        }

    }, [initialValues, product]);

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                margin: 'auto',
                marginTop: '30px'
            }}
        >
            {isLoading && <MUILoader open />}
            <Box sx={{ mt: 1, flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Stack spacing={2}>
                            <TextField
                                label="Name"
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value);
                                    setValidationErrors((prev) => ({ ...prev, name: '' }));
                                }}
                                error={!!validationErrors.name}
                                helperText={validationErrors.name}
                                required
                            />
                            <Box position="relative" width="100%">
                                <TextField
                                    label="Details"
                                    value={details}
                                    onChange={(e) => {
                                        setDetails(e.target.value);
                                        setValidationErrors((prev) => ({ ...prev, details: '' }));
                                    }}
                                    error={!!validationErrors.details}
                                    helperText={validationErrors.details}
                                    multiline
                                    rows={6}
                                    required
                                    fullWidth
                                />
                                <Tooltip
                                    title="Provide details about the product, such as its purpose and need."
                                    componentsProps={{
                                        tooltip: {
                                            sx: {
                                                fontSize: "0.8rem",
                                                padding: "8px",
                                            },
                                        },
                                    }}>
                                    <IconButton
                                        sx={{
                                            position: "absolute",
                                            top: 0,
                                            right: 5,
                                        }}
                                    >
                                        <InfoIcon sx={{ fontSize: "1.1rem" }} />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            <Box
                                sx={{
                                    border: `1px solid ${theme.palette.divider}`,
                                    borderRadius: '8px',
                                    padding: 2,
                                }}
                            >
                                <Typography variant="body2" color="textSecondary" mb={0.4}>
                                    Logo
                                </Typography>

                                {!logo ? (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            cursor: "pointer",
                                            width: "100%",
                                            height: "100%",
                                        }}
                                    >
                                        <IconButton
                                            onClick={() => document.querySelector("#logo-input").click()}
                                            sx={{
                                                color: "black",
                                                backgroundColor: "gray",
                                                "&:hover": { backgroundColor: "white" },
                                            }}
                                        >
                                            <AddSharpIcon />
                                        </IconButton>
                                        <input
                                            type="file"
                                            id="logo-input"
                                            hidden
                                            accept="image/*"
                                            onChange={handleAddLogo}
                                        />
                                    </Box>
                                ) : (

                                    <Box
                                        sx={{
                                            position: "relative",
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                        onMouseEnter={() => setHover(true)}
                                        onMouseLeave={() => setHover(false)}
                                    >
                                        <img
                                            src={logo}
                                            alt="Uploaded Logo"
                                            style={{
                                                maxWidth: "80%",
                                                maxHeight: "80%",
                                                objectFit: "contain",
                                                borderRadius: "8px",
                                            }}
                                        />

                                        {hover && (
                                            <>
                                                {/* Delete Button */}
                                                <IconButton
                                                    onClick={handleDelete}
                                                    sx={{
                                                        position: "absolute",
                                                        top: 10,
                                                        right: 10,
                                                        backgroundColor: "rgba(0,0,0,0.6)",
                                                        color: "white",
                                                        "&:hover": { backgroundColor: "red" },
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>

                                                {/* Download Button */}
                                                {/* <IconButton
                                                    onClick={handleDownload}
                                                    sx={{
                                                        position: "absolute",
                                                        top: 10,
                                                        left: 10,
                                                        backgroundColor: "rgba(0,0,0,0.6)",
                                                        color: "white",
                                                        "&:hover": { backgroundColor: "blue" },
                                                    }}
                                                >
                                                    <DownloadIcon />
                                                </IconButton> */}
                                            </>
                                        )}
                                    </Box>
                                )}

                                {logoName && (
                                    <Typography variant="body2" textAlign="center" mt={1} color="textSecondary">
                                        {logoName}
                                    </Typography>
                                )}
                                {/* {validationErrors.logo && (
                                <Box sx={{ color: 'error.main', mt: 1 }}>
                                {validationErrors.logo}
                                </Box>
                                )} */}
                            </Box>
                            {!hideFields?.includes('channels') && (<MUIAutocomplete
                                multiple
                                label="Channels"
                                selectedValue={channels}
                                apiType="REST"
                                apiRequest={{
                                    url: `/api/channels?populate=*&publicationState=preview&filters[$and][0][product][name][$null]=true`,
                                    method: 'GET',
                                }}
                                fieldKey="display_name"
                                onSelect={(channelObj) => {
                                    setChannels(channelObj);
                                    setValidationErrors((prev) => ({ ...prev, channels: '' }));
                                }}
                                autoHighlight
                                error={!!validationErrors.channels}
                                helperText={validationErrors.channels}
                            />)}

                            {/* Image Viewer Dialog */}
                            <Dialog
                                open={open}
                                onClose={handleClose}
                                maxWidth={false}
                                sx={{
                                    "& .MuiDialog-paper": {
                                        backgroundColor: "transparent",
                                        boxShadow: "none",
                                        overflow: "hidden",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "100vw",
                                        height: "100vh",
                                    },
                                }}
                            >
                                <DialogContent
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        position: "relative",
                                        width: "100%",
                                        height: "100%",
                                        backgroundColor: "transparent",
                                    }}
                                >
                                    <IconButton onClick={handleClose} color="primary"
                                        sx={{
                                            color: "white",
                                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                                            "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.7)" },
                                            position: "absolute",
                                            right: 30,
                                            top: 0,
                                        }}>
                                        <CloseIcon />
                                    </IconButton>
                                    <Box
                                        sx={{
                                            position: "absolute",
                                            left: 30,
                                            top: "50%",
                                            transform: "translateY(-50%)",
                                        }}>
                                        <PrevArrow onClick={handlePrev} disabled={productScreens.length <= 1} />
                                    </Box>

                                    <Zoom>
                                        <Box
                                            component="img"
                                            src={productScreens[currentIndex]?.attributes?.url}
                                            alt={`Product Screen ${currentIndex + 1}`}
                                            sx={{
                                                maxWidth: "90%",
                                                maxHeight: "90vh",
                                                objectFit: "contain",
                                                borderRadius: "8px",
                                                cursor: "zoom-in",
                                                // transition: "transform 0.3s ease-in-out",
                                                // "&:hover": {
                                                //     transform: "scale(1.1)",
                                                // },
                                            }}
                                        />
                                    </Zoom>

                                    <Box
                                        sx={{
                                            position: "absolute",
                                            right: 35,
                                            top: "50%",
                                            transform: "translateY(-50%)"
                                        }}
                                    >
                                        <NextArrow onClick={handleNext} disabled={productScreens.length <= 1} />
                                    </Box>
                                </DialogContent>
                            </Dialog>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack spacing={2}>
                            <TextField
                                label="Website URL"
                                placeholder='https://example.com'
                                value={url}
                                onChange={(e) => {
                                    setUrl(e.target.value);
                                    setValidationErrors((prev) => ({ ...prev, url: '' }));
                                }}
                                error={!!validationErrors.url}
                                helperText={validationErrors.url}
                                type="url"
                                required
                                sx={{ margin: '1px' }}
                                inputProps={{ 
                                    // pattern: "https://.*",
                                    sx: {
                                        "&::placeholder": {
                                          color: theme.palette.text.secondary, 
                                          opacity: 1, 
                                        },
                                      }, 
                                }}
                            InputProps={{
                                endAdornment: !formType && ((
                                    <InputAdornment position="end">
                                        <Tooltip title="Fetch Metadata">
                                            <IconButton color="primary" onClick={handleFetchMetaData} disabled={!url}>
                                                <FileDownloadIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                )),
                            }}
                            />
                            {description &&
                                <Box position="relative" width="100%">
                                    <TextField
                                        label="Description"
                                        value={description}
                                        onChange={(e) => {
                                            setDescription(e.target.value);
                                            setValidationErrors((prev) => ({ ...prev, description: '' }));
                                        }}
                                        // error={!!validationErrors.description}
                                        // helperText={validationErrors.description}
                                        multiline
                                        rows={6}
                                        fullWidth
                                    // InputProps={{ readOnly: true }}
                                    // required
                                    />
                                    <Tooltip
                                        title="Please review the description and make any necessary edits, as the generated content will be based on it"
                                        componentsProps={{
                                            tooltip: {
                                                sx: {
                                                    fontSize: "0.8rem",
                                                    padding: "8px",
                                                },
                                            },
                                        }}>
                                        <IconButton
                                            sx={{
                                                position: "absolute",
                                                top: 0,
                                                right: 5,
                                            }}
                                        >
                                            <InfoIcon sx={{ fontSize: "1.1rem" }} />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            }
                            {productScreens.length > 0 && (
                                <Box
                                    sx={{
                                        border: `1px solid ${theme.palette.divider}`,
                                        borderRadius: "8px",
                                        p: 1,
                                    }}
                                >
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        sx={{ position: "relative", }}
                                    >
                                        ProductScreens
                                    </Typography>
                                    <Box
                                        sx={{
                                            position: "relative",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            height: "250px",
                                            flexDirection: "column",
                                            p: 1,
                                        }}>

                                        <Box alignItems="center">
                                            <Box
                                                sx={{
                                                    position: "absolute",
                                                    left: 12,
                                                    top: "50%",
                                                    transform: "translateY(-50%)",
                                                    // backgroundColor: "rgba(0, 0, 0, 0.5)",
                                                    // color: "white",
                                                    // "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.7)" },
                                                }}>
                                                <PrevArrow onClick={handlePrev} disabled={productScreens.length <= 1} />
                                            </Box>
                                            <Tooltip title="Click to view" arrow>
                                                <Box
                                                    component="img"
                                                    src={productScreens[currentIndex]?.attributes ? productScreens[currentIndex]?.attributes?.url : productScreens[currentIndex].url}
                                                    alt="Product Screen"
                                                    sx={{
                                                        width: 250,
                                                        height: 200,
                                                        objectFit: "cover",
                                                        borderRadius: "4px",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => setOpen(true)}
                                                />
                                            </Tooltip>

                                            <Box onClick={handleNext} disabled={productScreens.length <= 1}
                                                sx={{
                                                    position: "absolute",
                                                    right: 12,
                                                    top: "50%",
                                                    transform: "translateY(-50%)",
                                                    // backgroundColor: "rgba(0, 0, 0, 0.5)",
                                                    // color: "white",
                                                    // "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.7)" },
                                                }}>
                                                <NextArrow onClick={handleNext} disabled={productScreens.length <= 1} />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            )}
                            {!hideFields?.includes('media') && (<Box
                                sx={{
                                    border: `1px solid ${theme.palette.divider}`,
                                    borderRadius: '8px',
                                    padding: 2,
                                }}
                            >
                                <Typography variant="body2" color="textSecondary">
                                    Media
                                </Typography>
                                <UploadFiles
                                    onFilesChange={(fileList) => {
                                        setFiles(fileList);
                                        setValidationErrors((prev) => ({ ...prev, files: '' }));
                                    }}
                                    existingMedia={product?.attributes?.media?.data || []}
                                    onRemovedFiles={setRemovedFiles}
                                    files={files}
                                />
                                {validationErrors.files && (
                                    <Box sx={{ color: 'error.main', mt: 1 }}>
                                        {validationErrors.files}
                                    </Box>
                                )}
                            </Box>)}
                            {!formType && <Box display="flex" justifyContent="flex-end" mt={2} gap={2}>
                                <Button type="submit" variant="contained" color="primary" disabled={isLoading}>
                                    {initialValues?.id || product ? 'Update Product' : 'Create Product'}
                                </Button>
                            </Box>}
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                                                                    {/* <Can
                            I="manage"
                            this={subject("ManageProducts", { productId: parseNumber(product?.id || currentProductId) })}
                            field="productId"
                        > */}
                        {formType === 'chatbot' && <Box display="flex" justifyContent="flex-start" mt={2} gap={2}>
                            <Button type="submit" variant="contained" color="primary" disabled={isLoading}>
                                { initialValues?.id || product ? 'Update Product' : 'Create Product'}
                            </Button>
                            {onCancel &&
                                <Button variant="outlined" onClick={onCancel}>
                                    Cancel
                                </Button>
                            }
                        </Box>}
                        {/* </Can> */}
                    </Grid>
                    {showUserFields && <Grid item xs={12}>
                        <Box>
                            <Can
                                I="manage"
                                this={subject("ManageUsers", { productId: parseNumber(currentProductId) })}
                                field="productId"
                            >
                                <Box display="flex" justifyContent="flex-end" alignItems="center">
                                    <Button variant="outlined" color="primary" onClick={handleOpenAddUserDialog}>
                                        Add User
                                    </Button>
                                </Box>
                            </Can>
                            <TableContainer component={Box} sx={{ mt: 2 }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Email</TableCell>
                                            <TableCell>Role</TableCell>
                                            <TableCell>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {users.map((user) => (
                                            <TableRow key={user.id}>
                                                <TableCell>{user.email}</TableCell>
                                                <TableCell>{user.role}</TableCell>
                                                <TableCell>
                                                    <Tooltip title="Delete">
                                                        <IconButton
                                                            variant="contained"
                                                            onClick={() => handleDeleteUser(user.id)}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Grid>}
                </Grid>
            </Box>

            <MUIModal
                open={openAddUserDialog}
                handleClose={() => {
                    handleCloseAddUserDialog();
                }}
                title="Add New User"
            >
                <Stack spacing={2}>
                    <TextField
                        label="Email"
                        value={newUser.email}
                        onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                        required
                    />
                    <TextField
                        select
                        label="Role"
                        value={newUser.role}
                        onChange={(e) => setNewUser({ ...newUser, role: e.target.value })}
                        required
                    >
                        <MenuItem value="Owner">Owner</MenuItem>
                        <MenuItem value="Editor">Editor</MenuItem>
                        <MenuItem value="Viewer">Viewer</MenuItem>
                    </TextField>
                </Stack>
                <DialogActions>
                    {/* <Button onClick={handleCloseAddUserDialog}>Cancel</Button> */}
                    <Can
                        I="manage"
                        this={subject("ManageUsers", { productId: parseNumber(currentProductId) })}
                        field="productId"
                    >
                        <Button onClick={handleAddUser} color="primary">Add</Button>
                    </Can>
                </DialogActions>
            </MUIModal>
        </Box>
    );
};

export default ProductForm;
