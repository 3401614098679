import React, { useState, useEffect, useRef } from 'react';
import makeRequest from '../../makeRequest';
import {
    Box,
    Grid,
    TextField,
    MenuItem,
    Button,
} from '@mui/material';
import { useDataContext } from '../../context/DataContext';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader';
import { Can } from '../../context/Can';
import { subject } from "@casl/ability";
import { clearMessages, setGlobalMessage, setSuccessMessage } from '../../Redux/slices/uiSlice';
import { useDispatch, useSelector } from "react-redux";
import { formatDateToUTC, formatDateWithTimezone, parseNumber, validateNumberField, validateVideoFile, validateImageFile, nowLocal } from '../../helpers';
import MediaSection from './MediaSection';

const ManualPostCreation = ({ currentProductId, handleClose }) => {
    const [channel, setChannel] = useState('');
    const [product, setProduct] = useState('');
    const [status, setStatus] = useState('');
    const [type, setType] = useState('');
    const [content, setContent] = useState('');
    const [mediaData, setMediaData] = useState([]);
    const [newMedia, setNewMedia] = useState([]);
    const { products, channels, medias, postTypes, loading, postStatusList, filteredPostTypes, setSelectedChannelType } = useDataContext();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const authState = useSelector((state) => state.auth);
    const user = authState.user;
    const [updatedMedia, setUpdatedMedia] = useState([]);
    const [users, setUsers] = useState(false);
    const navigate = useNavigate();
    const [validationErrors, setValidationErrors] = useState({});
    const [channelType, setChannelType] = useState('');
    const filteredChannels = channels?.filter(chan => chan.attributes.product?.data?.id === product);
    const [saving, setSaving] = useState(false);
    const userProducts = authState.user.userRoles?.map(item=>item.product) || [];
    const filteredProducts =  products.filter(fItem => userProducts.find(pItem=> fItem.id == pItem.id));
    const [frames, setFrames] = useState([]);
    const [selectedFrame, setSelectedFrame] = useState("");
    const [frameMedia, setFrameMedia] = useState(null);
    const dispatch = useDispatch();
    const uiState = useSelector((state) => state.ui);

    const [startDate, setStartDate] = useState('');
    const slider = useRef();

    useEffect(() => {
        if (user) {
            setUsers(user);
        }
    }, [user]);

    const validateFields = () => {
        const errors = {};
        if (!product) errors.product = "Product is required";
        if (!channelType) errors.channelType = "Select social media";
        if (!channel) errors.channel = "Channel is required";
        if (!startDate) errors.scheduled_date = "Scheduled time is required";
        if (!status) errors.status = "Select Status";
        if (!content.trim()) errors.content = "Content is required";
        if (!type) errors.type = "Select Type";
        const currentTime = new Date();
        const selectedTime = new Date(startDate);
        currentTime.setSeconds(0, 0);
        selectedTime.setSeconds(0, 0);      
        if (selectedTime.getTime() < currentTime.getTime()) {
          errors.scheduled_date = "Please select a time that is not earlier than the current time"
        }
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleCreatePost = async () => {
        if (!validateFields()) {
            return;
        }
        if (!user) {
            console.error("User not logged in");
            return;
        }
        const startDateObj = new Date(startDate);
        const startDateZone = formatDateWithTimezone(startDateObj);
        let mediaToUpload = [];
        if (['single-image-post', 'multi-images', 'story-image'].includes(type)) {
            const invalidMedia = newMedia.filter(
                (media) => media.file && media.file.type.startsWith('video/')
            );

            if (invalidMedia.length > 0) {
                setIsLoading(false);
                dispatch(setGlobalMessage({ message: "Video files are not allowed. Please upload only images!", severity: "error" }));
                // alert("Video files are not allowed. Please upload only images!");
                return;
            }
        } else if (['single-video-post', 'multi-videos', 'story-video','reels'].includes(type)) {
            const invalidMedia = newMedia.filter(
                (media) => media.file && !media.file.type.startsWith('video/')
            );

            if (invalidMedia.length > 0) {
                setIsLoading(false);
                dispatch(setGlobalMessage({ message: "Only video files are allowed. Please upload valid videos!", severity: "error" }));
                // alert("Only video files are allowed. Please upload valid videos!");
                return;
            }
        }

        if (type !== 'text' &&
            mediaData.length === 0 &&
            newMedia.length === 0
        ) {
            dispatch(setGlobalMessage({
                message: "No valid media selected!",
                severity: "error"
            }));
            return;
        }

        // -------- Handle Media Upload --------
        if (newMedia.length > 0) {
            const formData = new FormData();
            for (const mediaItem of newMedia) {
                formData.append('files', mediaItem?.file);
            }
            setSaving(true);
            try {
                const uploadResponse = await makeRequest({
                    method: "POST",
                    url: "/api/upload",
                    data: formData,
                    headers: { "Content-Type": "multipart/form-data" },
                });

                if (!uploadResponse) {
                    throw new Error("Failed to upload media.");
                }

                mediaToUpload = [...uploadResponse];
            } catch (error) {
                console.error("Media upload failed:", error);
                return;
            }
        }
        const dataToCreate = {
            product,
            channel: { connect: [{ id: channel }] },
            channelType,
            tm_scheduled: startDateZone,
            content: content,
            type: type,
            status: status,
            scheduled_by: { connect: [{ id: user.id }] },
            media: mediaToUpload,
            jsonData: { selectedFrame, frameMedia }
        };
        setSaving(true);
        try {
            const response = await makeRequest({
                method: 'POST',
                url: '/api/posts/create-manual',
                data: dataToCreate,
            });
            setType('');
            setContent('');
            setStartDate(null);
            setStatus('');
            setChannel('');
            setProduct('');
            setChannelType('');
            setUpdatedMedia([]);
            setNewMedia([]);
            setValidationErrors({});
            setOpenSnackbar(true);
            setSaving(false);
            dispatch(setGlobalMessage({message:"Post created successfully!",severity:"success"}));
            navigate('/schedule?view=calendar');
        } catch (error) {
            dispatch(setGlobalMessage({message:"Something went wrong ",severity:"error"}));
            console.error('Error saving manual post:', error);
        }
    };

    const handleAddMedia = async (event) => {
        dispatch(clearMessages());

        if (!event.target.files || event.target.files.length === 0) return;

        event.persist();
        const inputElement = event.target; 
        const files = Array.from(event.target.files);
        const validFiles = [];

        for (const file of files) {
            try {
                if (['single-image-post', 'multi-images', 'story-image'].includes(type)) {
                    const { errors: imageErrors, warnings: imageWarnings } = await validateImageFile(file, type, channelType);
                  
                    if (imageErrors.length > 0) {
                        dispatch(setGlobalMessage({
                            message: `Invalid file ${file.name}: ${imageErrors.join(', ')}`,
                            severity: "error"
                        }));
                        continue;
                    }
                    if (imageWarnings.length > 0) {
                        dispatch(setGlobalMessage({
                            message: `Warning for ${file.name}: ${imageWarnings.join(', ')}`,
                            severity: "warning"
                        }));
                    }
                }

                if (['reels', 'story-video', 'single-video-post', 'multi-videos'].includes(type)) {
                    const errors = await validateVideoFile(file, type, channelType);
                    const hardErrors = errors.filter(e => !e.includes('Recommended'));
                    const warnings = errors.filter(e => e.includes('Recommended'));
      
                    if (hardErrors.length > 0) {
                        dispatch(setGlobalMessage({
                            message: `Invalid file ${file.name}: ${hardErrors.join(', ')}`,
                            severity: "error"
                        }));
                        continue;
                    }
                    if (warnings.length > 0) {
                        dispatch(setGlobalMessage({
                            message: `Warning for ${file.name}: ${warnings.join(', ')}`,
                            severity: "warning"
                        }));
                    }
                }

                const isImage = file.type.startsWith('image/');
                const isVideo = file.type.startsWith('video/');
                const isAllowedVideoType = ['video/mp4', 'video/quicktime'].includes(file.type);

                if (type === 'single-video-post' || type === 'multi-videos' || type === 'reels' || type === 'story-video') {
                    if (!isVideo || !isAllowedVideoType) {
                        dispatch(setGlobalMessage({
                            message: "Only MP4/MOV videos allowed for this post type",
                            severity: "error"
                        }));
                        continue;
                    }
                } else if (type === 'single-image-post' || type === 'multi-images' || type === 'story-image') {
                    if (!isImage) {
                        dispatch(setGlobalMessage({
                            message: "Only images allowed for this post type",
                            severity: "error"
                        }));
                        continue;
                    }
                }

                if ((type === "single-image-post" || type === "single-video-post") &&
                    (newMedia.length + validFiles.length > 1)) {
                    dispatch(setGlobalMessage({
                        message: "You can only add one media file for this post type",
                        severity: "error"
                    }));
                    break;
                }

                validFiles.push(file);

            } catch (error) {
                console.error("Error validating file:", error);

                if (event.target?.value) event.target.value = '';

                dispatch(setGlobalMessage({
                    message: `Error processing ${file.name}: ${error.message}`,
                    severity: "error"
                }));
            }
        }

        if (validFiles.length === 0) {
            inputElement.value = '';
            return;
        }

        try {
            const formattedFiles = validFiles.reverse().map((file) => ({
                file,
                img: URL.createObjectURL(file),
                tempId: Date.now() + Math.random(),
            }));

            setNewMedia(prev => [...formattedFiles, ...prev]);

            if (slider.current) {
                setTimeout(() => slider.current.slickGoTo(0), 300);
            }
            inputElement.value = ''; 
        } catch (error) {
            inputElement.value = ''; 
            console.error("Error creating object URLs:", error);
            dispatch(setGlobalMessage({
                message: "Error processing selected files",
                severity: "error"
            }));
        }
    };

    const handleDeleteMedia = (dItem) => {
        if (dItem.tempId) {
          setNewMedia(prev => prev.filter(item => item.tempId !== dItem.tempId));
        } 
        else {
          setMediaData(prev => prev.filter(item => item.id !== dItem.id));
        }
    };

    useEffect(() => {
        if (product && channelType) {
            const matchingChannel = filteredChannels.find(chan => chan.attributes.channel_type === channelType);
            if (matchingChannel) {
                setChannel(matchingChannel.id);
            } else {
                setChannel('');
            }
        }
    }, [product, channelType, filteredChannels]);

    const channelOptions = channels.map(channelItem => ({
        id: channelItem.id,
        label: `${channelItem.attributes.display_name} - ${channelItem.attributes.channel_type}`,
    }));

    const handleChannelTypeChange = (e) => {
        const selectedChannelType = e.target.value;
        setChannelType(selectedChannelType);
        setSelectedChannelType(selectedChannelType);
        setType('');
    };
    const formatPostType = (postType) => {
        return postType
            .replace(/-/g, ' ')
            .replace(/\b\w/g, char => char.toUpperCase());
    };
    
    const handleProductSelection = (e) =>{
        setProduct(e.target.value);
        const prodData = filteredProducts.find(pItem => pItem.id === e.target.value);
        const fetchedFrames = prodData?.attributes?.frames || [];
        setFrames(fetchedFrames);
    }

    const handleFrameChange = (e) => {
        const frameId = e.target.value;
        setSelectedFrame(frameId);
        const frame = frames.find(f => f.id === frameId);
        setFrameMedia(frame?.media?.data?.attributes?.url || "");
    };

    const handleClearFrame = () => {
        setSelectedFrame("");
        setFrameMedia(null);
    };

    return (
        <Box component="main">
            <Box>
                {isLoading && <Loader />}
                <Grid container spacing={2}>

                    <Grid item xs={12} md={6}>
                        <TextField
                            select
                            label="Select Product"
                            fullWidth
                            value={product}
                            onChange={handleProductSelection}
                            required
                        >
                            {filteredProducts.map((pItem) => (
                                <MenuItem key={pItem.id} value={pItem.id}>
                                    {pItem.attributes.name}
                                </MenuItem>
                            ))}
                        </TextField>
                        {validationErrors.product && (
                            <span style={{ color: 'red', fontSize: '12px' }}>{validationErrors.product}</span>
                        )}
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            select
                            label="Select Channel Type"
                            fullWidth
                            value={channelType}
                            onChange={handleChannelTypeChange}
                            required
                            disabled={!product}
                        >
                            {medias.map((mItem) => (
                                <MenuItem key={mItem.value} value={mItem.value}>
                                    {mItem.label}
                                </MenuItem>
                            ))}
                        </TextField>
                        {validationErrors.channelType && (
                            <span style={{ color: 'red', fontSize: '12px' }}>{validationErrors.channelType}</span>
                        )}
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            select
                            fullWidth
                            value={channel}
                            label="Channel"
                            onChange={e => setChannel(e.target.value)}
                            required
                            disabled={!product || !channelType}
                            InputProps={{
                                readOnly: true,
                            }}
                        >
                            {filteredChannels?.map((chan) => (
                                <MenuItem key={chan.id} value={chan.id}>
                                    {chan.attributes.display_name}
                                </MenuItem>
                            ))}
                        </TextField>
                        {validationErrors.channel && (
                            <span style={{ color: 'red', fontSize: '12px' }}>{validationErrors.channel}</span>
                        )}
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            select
                            value={type}
                            label="Post Type"
                            fullWidth
                            required
                            onChange={e => setType(e.target.value)}
                            disabled={!channelType}
                        >
                            {filteredPostTypes.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {formatPostType(option)}
                                </MenuItem>
                            ))}
                        </TextField>
                        {validationErrors.type && (
                            <span style={{ color: 'red', fontSize: '12px' }}>{validationErrors.type}</span>
                        )}
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Post Date"
                            type="datetime-local"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={startDate}
                            inputProps={{
                                min: nowLocal,
                            }}
                            onChange={(e) => setStartDate(e.target.value)}
                            required
                            FormHelperTextProps={{ error: Boolean(validationErrors?.scheduled_date), sx: { marginLeft: '0px' } }}
                        />
                            {validationErrors.scheduled_date && (
                                <span style={{ color: 'red', fontSize: '12px' }}>{validationErrors.scheduled_date}</span>
                            )}
                        
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            select
                            value={status}
                            label="Status"
                            fullWidth
                            required
                            onChange={e => setStatus(e.target.value)}
                        >
                            {postStatusList
                                .filter(option => option.value !== "published")
                                .map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                        </TextField>
                        {validationErrors.status && (
                            <span style={{ color: 'red', fontSize: '12px' }}>{validationErrors.status}</span>
                        )}
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            id="outlined-multiline-static"
                            label="Content"
                            multiline
                            rows={5}
                            value={content}
                            required
                            onChange={e => setContent(e.target.value)}
                            fullWidth
                        />
                        {validationErrors.content && (
                            <span style={{ color: 'red', fontSize: '12px' }}>{validationErrors.content}</span>
                        )}
                    </Grid>

                    {type !== 'text' && 
                    (<Grid item xs={12} md={6}>
                        <MediaSection
                            type={type}
                            mediaData={mediaData}
                            newMedia={newMedia}
                            onAddMedia={handleAddMedia}
                            onDeleteMedia={handleDeleteMedia}
                            frames={frames}
                            frameMedia={frameMedia}
                            selectedFrame={selectedFrame}
                            onFrameChange={handleFrameChange}
                            onClearFrame={handleClearFrame}
                            sliderRef={slider}
                            disabled={!channelType}
                        />
                    </Grid>)
                    }
                        <Can
                            I='manage'
                            this={subject('ManagePosts', { productId: parseNumber(currentProductId) })}
                            field='productId'
                        >
                        <Grid item xs={12}>
                            <Box display="flex" alignItems="center" justifyContent="flex-end">
                                <Button variant="contained" color="primary" onClick={handleCreatePost} disabled={saving}>
                                    {saving ? 'Saving...' : 'Save Post'}
                                </Button>
                            </Box>
                        </Grid>
                    </Can>
                </Grid>
            </Box>
        </Box>
    );
};
export default ManualPostCreation;
