import React from 'react';
import { Alert, Snackbar } from '@mui/material';

const SnackbarMessage = ({ open, message, onClose, severity = "success" }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        // onClose={onClose} 
        severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarMessage;
