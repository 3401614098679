import React, { useState, useRef, useEffect, Fragment } from 'react';
import { Box, Typography, IconButton, useTheme } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import { setGlobalMessage } from '../../Redux/slices/uiSlice';
import { useDispatch, useSelector } from "react-redux";
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import PrevArrow from '../../components/PrevArrow';
import NextArrow from '../../components/NextArrow';


const UploadFiles = ({ existingMedia = [], onFilesChange, onRemovedFiles }) => {
    const theme = useTheme();
    const [files, setFiles] = useState([]);
    const [removedFileIds, setRemovedFileIds] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(0);
    const fileInputRef = useRef(null);
    const dispatch = useDispatch();
    const [hover, setHover] = useState(false);

    useEffect(() => {
        if (existingMedia.length === 0) return;

        const initialFiles = existingMedia.map((media) => {
            let preview = media?.attributes?.formats?.thumbnail?.url || media.attributes.url
            if (media?.mime?.startsWith('video/')) {
                preview = media.attributes.url;
            }

            return ({
                id: media.id,
                file: null,
                preview,
                name: media.attributes.name || '',

            })
        });
        setFiles(initialFiles);
    }, [existingMedia]);

    const hasMedia = existingMedia.length > 0 || files.length > 0;

    const handleFileSelect = (event) => {
        const selectedFiles = Array.from(event.target.files);
        const supportedFiles = selectedFiles.filter((file) =>
            file.type.startsWith('image/') || file.type.startsWith('video/')
        );
        const previewFiles = supportedFiles.map((file) => {
            return ({
                id: null,
                file,
                preview: URL.createObjectURL(file),
                name: file.name || ''
            })
        });
        // setFiles((prevFiles) => {
        //     const updatedFiles = [...previewFiles, ...prevFiles];
        //     setCurrentSlide(previewFiles.length - 1); 
        //     return updatedFiles;
        // });
        // onFilesChange([...supportedFiles, ...files.map((f) => f.file).filter(Boolean)]);
        // event.target.value = null;
        setFiles((prevFiles) => [...previewFiles, ...prevFiles]);
        onFilesChange([...selectedFiles, ...files.map((f) => f.file).filter(Boolean)]);
    };

    const handleFileDrop = (event) => {
        event.preventDefault();
        const droppedFiles = Array.from(event.dataTransfer.files);
        const supportedFiles = droppedFiles.filter((file) =>
            file.type.startsWith('image/') || file.type.startsWith('video/')
        );
        const previewFiles = supportedFiles.map((file) => ({
            id: null,
            file,
            preview: URL.createObjectURL(file),
            name: file.name || ''
        }));
        setFiles((prevFiles) => {
            const updatedFiles = [...previewFiles, ...prevFiles];
            setCurrentSlide(previewFiles.length - 1);
            return updatedFiles;
        });
        onFilesChange([...supportedFiles, ...files.map((f) => f.file).filter(Boolean)]);
        dispatch(setGlobalMessage({ message: "Media added successfully!", severity: "success" }));
    };

    const handleRemoveFile = (index) => {
        const removedFile = files[index];
        if (removedFile.id) {
            const updatedRemovedFileIds = [...removedFileIds, removedFile.id];
            setRemovedFileIds(updatedRemovedFileIds);
            onRemovedFiles(updatedRemovedFileIds);
        }

        const updatedFiles = files.filter((_, i) => i !== index);
        setFiles(updatedFiles);
        onFilesChange(updatedFiles.map((f) => f.file).filter(Boolean));

        if (currentSlide >= updatedFiles.length && updatedFiles.length > 0) {
            setCurrentSlide(updatedFiles.length - 1);
        }
        // dispatch(setGlobalMessage({ message: "Media removed successfully!", severity: "success" }));
    };

    const handleDragOver = (event) => event.preventDefault();

    const handleNext = () => {
        if (currentSlide < files.length - 1) {
            setCurrentSlide((prev) => prev + 1);
        }
    };

    const handlePrev = () => {
        if (currentSlide > 0) {
            setCurrentSlide((prev) => prev - 1);
        }
    };

    return (
        <Box>
            {files.length > 0 ? (
                <Box position="relative" textAlign="center">
                    <PrevArrow onClick={handlePrev} disabled={currentSlide === 0} />
                    <Box
                        display="flex" flexDirection="column" justifyContent="center" alignItems="center"
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                        position="relative"
                        sx={{ height: "215px", overflow: "hidden",
                            display: "flex", 
                            alignItems: "center", 
                            justifyContent: "center",
                            borderRadius: "8px", 
                         }}
                         
                         >
                        <img src={files[currentSlide].preview} alt="Preview"
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                            borderRadius: "8px",
                            display: "block",
                          }}
                        />
                        <Box
                            className="hover-actions"
                            position="absolute"
                            left={0}
                            right={0}
                            bottom={0}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            bgcolor="rgba(0, 0, 0, 0.8)"
                            color="#fff"
                            sx={{
                                opacity: hover ? 1 : 0,
                                transition: 'opacity 0.7s ease-in-out',
                                borderRadius: '4px'
                            }}
                        >
                            {/* <IconButton
                                onClick={() => fileInputRef.current.click()}
                                sx={{
                                    color: 'black',
                                    backgroundColor: 'gray',
                                    '&:hover': { backgroundColor: 'white' },
                                    mr:2
                                }}
                            >
                                <AddIcon />
                            </IconButton> */}
                            <IconButton
                                onClick={() => handleRemoveFile(currentSlide)}
                                sx={{
                                    color: "black",
                                    backgroundColor: 'gray',
                                    '&:hover': { backgroundColor: 'red' },
                                }}
                            >
                                <DeleteOutlineOutlinedIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <NextArrow onClick={handleNext} disabled={currentSlide === files.length - 1} />
                </Box>
                // )}
            ) : (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        // width: "150px",
                        // height: "150px",
                        overflow: "hidden",
                    }}
                    onClick={() => fileInputRef.current.click()}
                >
                    <UploadIcon fontSize="large" />
                    <Typography variant="body2">
                        Drag & Drop files here or click to browse
                    </Typography>
                    <input
                        multiple
                        type="file" 
                        style={{ display: 'none' }}
                        onChange={handleFileSelect}
                        ref={fileInputRef}
                        accept="image/*,video/*"
                    />
                </Box>
            )}
        </Box>
    );
};

export default UploadFiles;
